import React, { Component } from "react";
import DocumentMeta from 'react-document-meta';
import {NavLink} from "react-router-dom";

import Cursor from "../components/Cursor"
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";
import MobileDetect from "mobile-detect";

export class News extends Component {

    constructor(props) {
        super(props);
        this.newsTitle = null;
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        //this.language =  window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.lang = this.language == 'en' ? '/en' : '';
        this.pageUrl = 'https://bitrix.rusneftekhim.com/init?json=1&news=1&lang=' + this.language;
        this.state = {
            error: null,
            isLoaded: false,
            news: [],
            page: []
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isLoaded: true,
                        news: result.NEWS,
                        page: result.PAGE
                    });

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        //prevent jerking news images
        setTimeout(function () {

            document.querySelectorAll('.imgItem').forEach(function (item) {
                item.classList.remove('preload');
            });

        }, 1500);

        const splitNewsTitle = new SplitText(this.newsTitle, {type: "chars"});
        TweenMax.staggerFrom(
            splitNewsTitle.chars,
            1,
            {
                yPercent: 50,
                opacity: 0
            },
            0.15
        );

        // helper functions
        const MathUtils = {
            // map number x from range [a, b] to [c, d]
            map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
            // linear interpolation
            lerp: (a, b, n) => (1 - n) * a + n * b,
            // Random float
            getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2)
        };

        // body element
        const body = document.body;
        //const body = document.querySelector('#newsScroller');

        // calculate the viewport size
        let winsize;
        const calcWinsize = () => winsize = {width: window.innerWidth, height: window.innerHeight};
        calcWinsize();
        // and recalculate on resize
        window.addEventListener('resize', calcWinsize);

        // scroll position
        let docScroll;
        // for scroll speed calculation
        let lastScroll;
        let scrollingSpeed = 0;
        // scroll position update function
        const getPageYScroll = () => docScroll = window.pageYOffset || document.documentElement.scrollTop;
        window.addEventListener('scroll', getPageYScroll);

        // Item
        class Item {
            constructor(el) {
                // the .item element
                this.DOM = {el: el};
                // the inner image
                this.DOM.image = this.DOM.el.querySelector('.imgItem');
                this.DOM.imageWrapper = this.DOM.image.parentNode;
                this.renderedStyles = {
                    // here we define which property will change as we scroll the page and the item is inside the viewport
                    // in this case we will be:
                    // - scaling the inner image
                    // we interpolate between the previous and current value to achieve a smooth effect
                    imageScale: {
                        // interpolated value
                        previous: 0,
                        // current value
                        current: 0,
                        // amount to interpolate
                        ease: 0.1,
                        // current value setter
                        setValue: () => {
                            const toValue = 1.5;
                            const fromValue = 1;
                            const val = MathUtils.map(this.props.top - docScroll, winsize.height, -1 * this.props.height, fromValue, toValue);
                            return Math.max(Math.min(val, toValue), fromValue);
                        }
                    }
                };
                // gets the item's height and top (relative to the document)
                this.getSize();
                // set the initial values
                this.update();
                // use the IntersectionObserver API to check when the element is inside the viewport
                // only then the element styles will be updated
                this.observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0);
                });
                this.observer.observe(this.DOM.el);
                // init/bind events
                this.initEvents();
            }
            update() {
                // sets the initial value (no interpolation)
                for (const key in this.renderedStyles ) {
                    this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
                }
                // apply changes/styles
                this.layout();
            }
            getSize() {
                const rect = this.DOM.el.getBoundingClientRect();
                this.props = {
                    // item's height
                    height: rect.height,
                    // offset top relative to the document
                    top: docScroll + rect.top
                }
            }
            initEvents() {
                window.addEventListener('resize', () => this.resize());
            }
            resize() {
                // gets the item's height and top (relative to the document)
                this.getSize();
                // on resize reset sizes and update styles
                this.update();
            }
            render() {
                // update the current and interpolated values
                for (const key in this.renderedStyles ) {
                    this.renderedStyles[key].current = this.renderedStyles[key].setValue();
                    this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
                }

                // and apply changes
                this.layout();
            }
            layout() {
                // scale the image
                this.DOM.image.style.transform = `scale3d(${this.renderedStyles.imageScale.previous},${this.renderedStyles.imageScale.previous},1)`;
            }
        }

        // SmoothScroll
        class SmoothScroll {
            constructor() {
                // the <main> element
                this.DOM = {main: document.querySelector('.wrapper')};
                // the scrollable element
                // we translate this element when scrolling (y-axis)
                this.DOM.scrollable = this.DOM.main.querySelector('div[data-scroll]');
                // the items on the page
                this.items = [];
                this.DOM.content = this.DOM.main.querySelector('.newsList');
                [...this.DOM.content.querySelectorAll('.newsItem')].forEach(item => this.items.push(new Item(item)));
                // here we define which property will change as we scroll the page
                // in this case we will be translating on the y-axis
                // we interpolate between the previous and current value to achieve the smooth scrolling effect
                this.renderedStyles = {
                    translationY: {
                        // interpolated value
                        previous: 0,
                        // current value
                        current: 0,
                        // amount to interpolate
                        ease: 0.1,
                        // current value setter
                        // in this case the value of the translation will be the same like the document scroll
                        setValue: () => docScroll
                    }
                };
                // set the body's height
                this.setSize();
                // set the initial values
                this.update();
                // the <main> element's style needs to be modified
                this.style();
                // init/bind events
                this.initEvents();
                // start the render loop
                requestAnimationFrame(() => this.render());
            }
            update() {
                // sets the initial value (no interpolation) - translate the scroll value
                for (const key in this.renderedStyles ) {
                    this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
                }
                // translate the scrollable element
                this.layout();
            }
            layout() {
                this.DOM.scrollable.style.transform = `translate3d(0,${-1*this.renderedStyles.translationY.previous}px,0)`;
            }
            setSize() {
                // set the height of the body in order to keep the scrollbar on the page
                let bodyHeight = `${this.DOM.scrollable.scrollHeight + 200}px`;
                body.style.height = bodyHeight;
            }
            style() {
                // the <main> needs to "stick" to the screen and not scroll
                // for that we set it to position fixed and overflow hidden
                this.DOM.main.style.position = 'fixed';
                this.DOM.main.style.width = this.DOM.main.style.height = '100%';
                this.DOM.main.style.top = this.DOM.main.style.left = 0;
                this.DOM.main.style.overflow = 'hidden';
            }
            initEvents() {
                // on resize reset the body's height
                window.addEventListener('resize', () => this.setSize());
            }
            render() {
                // Get scrolling speed
                // Update lastScroll
                scrollingSpeed = Math.abs(docScroll - lastScroll);
                lastScroll = docScroll;

                // update the current and interpolated values
                for (const key in this.renderedStyles ) {
                    this.renderedStyles[key].current = this.renderedStyles[key].setValue();
                    this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
                }
                // and translate the scrollable element
                this.layout();

                // for every item
                for (const item of this.items) {
                    // if the item is inside the viewport call it's render function
                    // this will update item's styles, based on the document scroll value and the item's position on the viewport
                    if ( item.isVisible ) {
                        if ( item.insideViewport ) {
                            item.render();
                        }
                        else {
                            item.insideViewport = true;
                            item.update();
                        }
                    }
                    else {
                        item.insideViewport = false;
                    }
                }

                // loop..
                requestAnimationFrame(() => this.render());
            }

        }

        if(this.isMobile){
            window.addEventListener('load', function () {
                setTimeout(function () {
                    getPageYScroll();
                    lastScroll = docScroll;
                    // Initialize the Smooth Scrolling
                    new SmoothScroll();
                }, 2000);
            });
        } else {
            setTimeout(function () {
                getPageYScroll();
                lastScroll = docScroll;
                // Initialize the Smooth Scrolling
                new SmoothScroll();
            }, 2000);
        }

    }

    render() {

        const { news, page } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/news',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
                <div className="wrapper">
                    <div id="newsContainer" className="container">
                        <div data-scroll>

                            <div className="col-row">
                                <div className="col-8-12 col-sm-7-12 hidden-xs">
                                </div>
                                <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                    <h1 id="newsTitle" ref={h1 => this.newsTitle = h1}>{page.NAME}</h1>
                                </div>
                            </div>

                            <div className="newsList">

                                {news.map(item =>
                                    <NavLink className="newsItem pullLeft" to={'news/' + item.CODE}>
                                        <div className="imgWrapper">
                                            <div className="imgItem preload" style={{backgroundImage: 'url(https://bitrix.rusneftekhim.com/upload/' + item.SUBDIR + '/' + item.FILE_NAME + ')'}}>
                                                <img src={"https://bitrix.rusneftekhim.com/upload/" + item.SUBDIR + '/' + item.FILE_NAME} alt={item.NAME} />
                                            </div>
                                        </div>
                                        <h2>{item.NAME}</h2>
                                        <p>{item.PREVIEW_TEXT} <small>{item.ACTIVE_FROM}</small></p>
                                    </NavLink>
                                )}

                            </div>

                        </div>
                    </div>
                </div>
                <Cursor/>
            </DocumentMeta>
        );

    }

}
