import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';

import Cursor from "../components/Cursor"
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";

export class NotFound extends Component {

    constructor(props) {
        super(props);
        this.aboutTitle = null;
        this.aboutText = null;
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.title = '404 Page not found';
        this.description = '';
        this.lang = this.language == 'en' ? '/en' : '';
        this.state = {
            error: null,
            isLoaded: false,
        };
    }

    componentDidMount() {

        const splitAboutTitle = new SplitText(this.aboutTitle, {type: "chars"});
        TweenMax.staggerFrom(splitAboutTitle.chars, 1, {yPercent: 50, opacity: 0}, 0.15);

    }

    render() {

        var meta = {
            title: this.title,
            description: this.description,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
            <div className="wrapper">
                <div className="absoluteContainer container">
                    <div className="absoluteWrapper">
                        <div className="col-row">
                            <div className="col-8-12 col-sm-7-12 hidden-xs">
                            </div>
                            <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                <h1 id="aboutTitle" ref={h1 => this.aboutTitle = h1}>404</h1>
                            </div>
                        </div>
                        <div className="col-row">
                            <div className="col-1-2 col-sm-7-12 col-xs-12-12"></div>
                        </div>
                    </div>
                </div>
                <Cursor/>
            </div>
            </DocumentMeta>
        );

    }

}