import React, { Component } from "react";
//import Sketch from "react-p5";
import p5 from  "p5";

/*export default class Waves extends Component {

    constructor() {
        super();
        this.windowResized = this.windowResized.bind(this);
    }

    height = window.innerHeight/2;
    width = window.innerWidth;

    step = (p5, phase, amplitude) => {

        let linesAmount = 9,
            burger = document.querySelector('a.burger'),
            orange = 'rgba(255, 89, 0, 1)',
            orangePosition = 2;

        if(document.body.classList.contains('leadership')){
            orangePosition = 1.425;
        }

        if(burger.classList.contains('active') ||
            document.body.classList.contains('noOrange') ||
            document.body.classList.contains('media')){
            orange = 'rgba(255, 255, 255, .15)';
        }

        for(var k = 0; k < linesAmount; k++) {

            let x0 = window.innerWidth/orangePosition - 72,
                x1 = window.innerWidth/orangePosition + 72;

            const grad = p5.drawingContext.createLinearGradient(x0, 0, x1, 0);

            grad.addColorStop(0, 'rgba(255,255,255,.15)');
            grad.addColorStop(0.5, orange);
            grad.addColorStop(1, 'rgba(255,255,255,.15)');

            p5.drawingContext.strokeStyle = grad;

            const offset = (1 - k / linesAmount) * 4;
            p5.beginShape();
            for (var i = 0; i < (this.width + 4); i+=4) {
                let y = this.height * 1;
                y += Math.sin(i * 0.01 - phase + offset) * amplitude;
                y += Math.sin(i * 0.02 - phase + offset) * amplitude;
                y += Math.sin(i * 0.04 - phase + offset) * amplitude;
                p5.vertex(i, y);
            }
            p5.endShape();
        }

    }

    setup = (p5, canvasParentRef) => {
        let size = Math.min(p5.windowWidth, p5.windowHeight) * 0.96;
            size = Math.floor(size);
        p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
        //p5.createCanvas(p5.windowWidth, p5.windowHeight);
        p5.noiseSeed(p5.random(100));
        p5.mouseY = this.height / 2;
        p5.noFill();
    }

    windowResized  = (p5, canvasParentRef) => {
        //console.log(this);
        //console.log(p5.canvas);
        // let size = Math.min(p5.windowWidth, p5.windowHeight) * 0.96;
        //     size = Math.floor(size);
        // /!*p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
        // p5.noiseSeed(p5.random(100));
        // p5.draw();*!/
        // p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
        // p5.noiseSeed(p5.random(100));
        // this.draw(p5);

        //console.log(window.innerWidth);
        //console.log(p5.windowWidth);
        //console.log(window.innerHeight);

        let size = Math.min(p5.windowWidth, p5.windowHeight) * 0.96;
            size = Math.floor(size);
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
        p5.noiseSeed(p5.random(100));
        p5.draw(p5);
    }

    draw = p5 => {
        p5.clear();
        const phase = p5.millis() * 0.0005;
        const amplitude = this.height * 0.1;
        this.step(p5, phase, amplitude)

        // let fps = p5.frameRate();
        // p5.stroke(0);
        // p5.text("FPS: " + fps.toFixed(2), 10, this.height - 10);
    }

    render() {
        return <Sketch setup={this.setup} draw={this.draw} windowResized={this.windowResized} />;
    }

}*/

export default class Waves extends Component {

    constructor(){
        super();
        this.renderRef = React.createRef();
    }

    componentDidMount(){
        this.sketch = new p5( p => {

            this.height = window.innerHeight/2;
            this.width = window.innerWidth;

            p.step = (phase, amplitude) => {

                let linesAmount = 9,
                    burger = document.querySelector('a.burger'),
                    orange = 'rgba(255, 89, 0, 1)',
                    orangePosition = 2;

                if(document.body.classList.contains('leadership')){
                    orangePosition = 1.425;
                }

                if(burger.classList.contains('active') ||
                    document.body.classList.contains('noOrange') ||
                    document.body.classList.contains('geography') ||
                    document.body.classList.contains('media')){
                    orange = 'rgba(255, 255, 255, .15)';
                }

                for(var k = 0; k < linesAmount; k++) {

                    let x0 = window.innerWidth/orangePosition - 72,
                        x1 = window.innerWidth/orangePosition + 72;

                    const grad = p.drawingContext.createLinearGradient(x0, 0, x1, 0);

                    grad.addColorStop(0, 'rgba(255,255,255,.15)');
                    grad.addColorStop(0.5, orange);
                    grad.addColorStop(1, 'rgba(255,255,255,.15)');

                    p.drawingContext.strokeStyle = grad;

                    const offset = (1 - k / linesAmount) * 4;
                    p.beginShape();
                    for (var i = 0; i < (this.width + 4); i+=4) {
                        let y = this.height * 1;
                        y += Math.sin(i * 0.01 - phase + offset) * amplitude;
                        y += Math.sin(i * 0.02 - phase + offset) * amplitude;
                        y += Math.sin(i * 0.04 - phase + offset) * amplitude;
                        p.vertex(i, y);
                    }
                    p.endShape();
                }

            }

            p.setup = () => {
                let size = Math.min(p.windowWidth, p.windowHeight) * 0.96;
                    size = Math.floor(size);
                p.createCanvas(p.windowWidth, p.windowHeight).parent(this.renderRef.current);
                p.noiseSeed(p.random(100));
                p.mouseY = this.height / 2;
                p.noFill();
            }

            p.windowResized  = () => {
                let size = Math.min(p.windowWidth, p.windowHeight) * 0.96;
                    size = Math.floor(size);
                p.resizeCanvas(p.windowWidth, p.windowHeight);
                p.noiseSeed(p.random(100));
                p.draw();
            }

            p.draw = () => {
                p.clear();
                const phase = p.millis() * 0.0005;
                const amplitude = this.height * 0.1;
                p.step(phase, amplitude)
            }

        });

    }
    render(){
        return (
            <div className="waves" ref={this.renderRef} windowResized={this.windowResized} />
        );
    }
}
