import GlTransitions from "gl-transitions";
import CreateGlTransition from "gl-transition";
import CreateGlTexture from "gl-texture2d";
import {TweenLite} from "gsap/all";

export function Transitions(prevImg, nextImg, fadeOut = true, effect = 'morph', isMobile = null) {

        prevImg = "/" + prevImg;
        nextImg = "/" + nextImg;

        const imageFrom = new Image();
        imageFrom.onload = () => (imageFrom.image = imageFrom);
        imageFrom.crossOrigin = "Anonymous";
        imageFrom.src = prevImg;

        const imageTo = new Image();
        imageTo.onload = () => (imageTo.image = imageTo);
        imageTo.crossOrigin = "Anonymous";
        imageTo.src = nextImg;

        var canvas = document.getElementById('transition');

        if(!canvas){
                canvas = document.createElement("canvas");
                canvas.id = 'transition';
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
                document.body.appendChild(canvas);
        } else {
                canvas.style.opacity = 1;
        }

        const gl = canvas.getContext('webgl');
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
        const buffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.bufferData(
            gl.ARRAY_BUFFER,
            new Float32Array([-1, -1, -1, 4, 4, -1]), // see a-big-triangle
            gl.STATIC_DRAW
        );
        gl.viewport(0, 0, window.innerWidth, window.innerHeight);

        const loadImage = src => {
                return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.addEventListener("load", () => resolve(img));
                        img.addEventListener("error", err => reject(err));
                        img.src = src;
                });
        }

        /*const loadImage = function loadImg(src) {
                'use strict';

                const paths = Array.isArray(src) ? src : [src];
                const promise = [];

                paths.forEach((path) => {
                        promise.push(new Promise((resolve, reject) => {
                                const img = new Image();

                                img.onload = () => {
                                        resolve({
                                                path,
                                                status: 'ok',
                                        });
                                };

                                // Call `resolve` even if the image fails to load. If we were to
                                // call `reject`, the whole "system" would break
                                img.onerror = () => {
                                        resolve({
                                                path,
                                                status: 'error',
                                        });
                                };

                                img.src = path;
                        }));
                });

                return Promise.all(promise);
        };*/

        Promise.all([prevImg, nextImg].map(loadImage))
            .then(imgs => {

                    const from = CreateGlTexture(gl, imgs[0]);
                    from.minFilter = gl.LINEAR;
                    from.magFilter = gl.LINEAR;

                    const to = CreateGlTexture(gl, imgs[1]);
                    to.minFilter = gl.LINEAR;
                    to.magFilter = gl.LINEAR;
                    //colorphase, ColourDistance, crosswarp, CrossZoom, directionalwarp, directionalwipe, displacement, DoomScreenTransition, Dreamy, GlitchDisplace, InvertedPageCurl, kaleidoscope, luminance_melt, multiply_blend, ripple
                    const transition = CreateGlTransition(gl, GlTransitions.find(t => t.name === effect));

                    //document.getElementById('transition').style.opacity = 1;

                    for (let i = 1; i <= 101; i++) {
                            (function(t) {
                                    setTimeout(function() {

                                            if(t <= 100){

                                                    transition.draw(t/100, from, to, window.innerWidth, window.innerHeight, { persp: 1.5, unzoom: 0.6 });

                                                    //prevent twitching
                                                    if(document.getElementById('transition') && document.getElementById('mainImage')){
                                                        let transitionStyle = document.getElementById('transition').getAttribute('style');
                                                        document.getElementById('mainImage').setAttribute('style', transitionStyle);
                                                    }

                                            }else{
                                                    if(fadeOut){

                                                            document.getElementById('transition').style.opacity = 0;

                                                    } else {


                                                            /* Tilt was here */

                                                    }
                                            }

                                    }, i * 20);
                            })(i);
                    }

                    /* Tilt */

                    if(!isMobile){

                            let tiltBg = document.querySelector('canvas#transition');

                            function tiltMain(e) {

                                    TweenLite.to(tiltBg, 0.6, {
                                            top: '0%',
                                            left: '0%',
                                            x: -e.clientX/72,
                                            y: -e.clientY/72,
                                    });

                            }

                            document.body.addEventListener('mousemove', tiltMain);

                    }


            });

}