import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import {TweenMax} from "gsap";
import {SplitText} from "gsap/SplitText";

import Cursor from "../components/Cursor";

export class Home extends Component {

    constructor(props) {
        super(props);
        // reference to the DOM node
        this.homeTitle = null;
        // reference to the animation
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.lang = this.language == 'en' ? '/en' : '';
        this.pageUrl = this.language == 'ru' ? 'https://bitrix.rusneftekhim.com/init?json=1&page=home&lang=ru' : 'https://bitrix.rusneftekhim.com/init?json=1&page=home_en&lang=en';
        this.state = {
            error: null,
            isLoaded: false,
            page: []
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    page: result[0]
                });

                const splitHomeTitle = new SplitText(this.homeTitle, {type: "chars"});
                TweenMax.staggerFrom(splitHomeTitle.chars, 1, {yPercent: 50, opacity: 0}, 0.15);

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }

    render() {

        const { page } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
            <div id="wrapperHome" className="wrapper">
                <div className="container">
                    <h1 id="homeTitle" className={'homeTitle_' + this.language} ref={h1 => this.homeTitle = h1} dangerouslySetInnerHTML={{__html: page.DESCRIPTION}} />
                </div>
                <Cursor/>
            </div>
            </DocumentMeta>
        )

    }

}
