import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import { TweenLite, TimelineLite } from "gsap/all";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import MobileDetect from "mobile-detect";
import {clearIntervalAsync} from "set-interval-async";
import {setIntervalAsync} from "set-interval-async/dynamic";

import Cursor from "../components/Cursor"
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";

const optionPhoto = {
    overlayColor: "rgb(34, 69, 91)",
    showCaption: false,
    buttonsBackgroundColor: "rgba(255, 89, 0, 0.8)",
    buttonsIconColor: "rgba(255, 255, 255, 1)",
    showThumbnails: false,
    transitionSpeed: 0,
    transitionTimingFunction: "linear"
};

export class Media extends Component{

    constructor(props) {
        super(props);
        this.mediaTitle = null;
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.lang = this.language == 'en' ? '/en' : '';
        this.pageUrl = 'https://bitrix.rusneftekhim.com/init?json=1&media=1&lang=' + this.language;
        this.state = {
            error: null,
            isLoaded: false,
            mediaItems: [],
            page: []
        };

        if(this.language == 'en'){
            this.media = 'Media';
            this.photo = 'Photo';
            this.video = 'Video';
        } else {
            this.media = 'Медиа';
            this.photo = 'Фото';
            this.video = 'Видео';
        }

        this.sliderInterval = null;
        this.changeTab = this.changeTab.bind(this);
        this.container = React.createRef();

        this.startCircle = this.startCircle.bind(this);
        this.goNext = this.goNext.bind(this);
        this.goCircle = this.goCircle.bind(this);
        this.goPagination = this.goPagination.bind(this);

    }

    goPagination(e) {

        e.preventDefault();

        if(document.querySelector('#mediaListSlider .active')){

            clearIntervalAsync(this.sliderInterval);

            this.sliderInterval = setIntervalAsync(
                () => {

                    let activeItem = document.querySelector('#mediaListSlider .active'),
                        nextItem = activeItem.nextSibling;

                    if (!nextItem) {
                        nextItem = document.querySelectorAll('#mediaListSlider a')[0]
                    }

                    this.goNext(nextItem);

                },
                6000
            )

            let activeItem = document.querySelector('#mediaListSlider .active'),
                nextItem = activeItem.nextSibling;

            if (!nextItem) {
                nextItem = document.querySelectorAll('#mediaListSlider a')[0]
            }

            this.goNext(e.currentTarget);

        }

    }

    goNext(item) {

        var that = this;

        let mediaList = document.querySelector('#mediaListSlider');

        if (!mediaList.classList.contains('wait') && !item.classList.contains('active')) {

            mediaList.classList.add('wait');

            let currentItemId = document.querySelector('#mediaListSlider a.active').id,
                currentItem = document.getElementById(currentItemId),
                currentImageId = currentItemId + 'Image',
                currentImage = document.getElementById(currentImageId),
                nextItemId = item.id,
                nextItem = document.getElementById(nextItemId),
                nextImageId = nextItemId + 'Image',
                nextImage = document.getElementById(nextImageId);

            let TL = new TimelineLite();

            //current
            TL.fromTo(currentImage.querySelector('.itemTitle'), 0.5, {opacity: 1, x: 0, zIndex: 1}, {
                opacity: 1, x: -100, zIndex: 1, onComplete: function () {
                    that.startCircle();
                }
            }, "0");
            TL.to(currentImage.querySelector('.itemTitle'), 0.5, {opacity: 0, x: 100, zIndex: -999}, "+=0.1");
            TL.fromTo(currentImage.querySelector('img'), 0.5, {x: 0}, {x: 100}, "-=1.1");
            TL.to(currentImage.querySelector('img'), 0.5, {x: 0}, "-=0.5");
            TL.to(currentImage.querySelector('img'), 0.5, {opacity: 0}, "-=0");
            TL.to(currentItem, 0.1, {className: "-=active"}, "+=0.1");
            TL.to(currentImage, 0.1, {className: "-=active"}, "+=0.1");

            //next
            TL.to(nextItem, 0.1, {
                className: "+=active", onComplete: function () {
                    that.goCircle();
                }
            }, "+=0.1");
            TL.to(nextImage, 0.1, {className: "+=active"}, "+=0.1");
            TL.fromTo(nextImage.querySelector('.itemTitle'), 0.5, {opacity: 0, x: 100, zIndex: 1}, {
                opacity: 1,
                x: 0,
                zIndex: 1
            }, "+=0");
            TL.fromTo(nextImage.querySelector('img'), 0.5, {opacity: 0, x: -100}, {opacity: 1, x: 0}, "-=.35");
            TL.to(mediaList, 0.1, {className: "-=wait"}, "+=0.1");

            let curNum = parseInt(currentItemId.match(/\d+/));

            let mediaListNavs = document.querySelectorAll('#mediaListSlider a');

            if(curNum == mediaListNavs.length){
                curNum = 0;
            }

            let listStep = curNum/7;

            if(Number.isInteger(listStep)){
                TL.to('#mediaListSliderItems', 0.5, {marginTop: -(document.getElementById('mediaListSlider').offsetHeight - 5) * listStep}, "-=0.25");
            }


        } else {

            return false;

        }

    }

    startCircle() {
        TweenLite.to('#mediaListSlider .active .circle', 1.2, {
            'stroke-dashoffset': -164, ease: "QuartOut",
            onComplete: function onComplete() {
                TweenLite.set(this.target, {
                    'stroke-dashoffset': 164
                });
            }
        });
    }

    goCircle() {
        TweenLite.to('#mediaListSlider .active .circle', 12, {'stroke-dashoffset': 0, delay: 1.2});
    }

    componentDidMount() {

        var that = this;

        const delayReport = deplayMs => new Promise((resolve) => {
            setTimeout(resolve, deplayMs);
        });

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    mediaItems: result.MEDIA,
                    page: result.PAGE,
                });

                const splitMediaTitle = new SplitText(this.mediaTitle, {type: "chars"});
                TweenMax.staggerFrom(
                    splitMediaTitle.chars,
                    1,
                    {
                        yPercent: 50,
                        opacity: 0
                    },
                    0.15
                );

                if (this.container.current) {

                    let mediaList = document.querySelector('#mediaListSlider'),
                        mediaListItems = document.querySelectorAll('#mediaListSlider a');

                    that.sliderInterval = setIntervalAsync(
                    () => {
                        let activeItem = document.querySelector('#mediaListSlider .active'),
                            nextItem = activeItem.nextSibling;

                        if (!nextItem) {
                            nextItem = document.querySelectorAll('#mediaListSlider a')[0]
                        }

                        this.goNext(nextItem);
                    }, 6000);

                    this.goCircle();

                }

                //Cursor.prototype.render();

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }

    componentWillUnmount() {
        clearIntervalAsync(this.sliderInterval);
    }

    changeTab(e) {

        e.preventDefault();

        let mediaTabs = document.querySelectorAll('#mediaTabs a');

        let activeTabId = e.target.getAttribute('href').substr(1);

        mediaTabs.forEach(function (item) {

            item.classList.remove('active');

        });

        e.target.classList.add('active');

        let mediaSelector = document.querySelector('.mediaSelector');

        mediaSelector.id = activeTabId + 'Selector';


        let mediaContainers = document.querySelectorAll('.mediaContainer');

        mediaContainers.forEach(function (item) {

            item.classList.remove('active');

        });

        document.getElementById(activeTabId).classList.add('active');

    }

    render() {

        const { mediaItems, page } = this.state;

        var that = this;

        function MySlider() {

            //myThis.forceUpdate();

            return (
                <div id="mediaSlider">
                    <SRLWrapper>
                        {mediaItems.map((item, index) =>
                        <a id={'item' + (index + 1) + 'Image'} key={'n' + (index + 1)} className={index == 0 ? "item active" : "item"} href={"https://bitrix.rusneftekhim.com/upload/" + item.SUBDIR + "/" + item.FILE_NAME} data-attribute="SRL">
                            <img src={"https://bitrix.rusneftekhim.com/upload/" + item.SUBDIR + "/" + item.FILE_NAME} alt={item.DESCRIPTION.replace(/<\/?[^>]+>/gi, '')} />
                            <span className="itemTitle" dangerouslySetInnerHTML={{__html: item.DESCRIPTION}} />
                        </a>
                        )}
                    </SRLWrapper>
                    <div id="mediaListSlider">
                        <div id="mediaListSliderItems">

                        {mediaItems.map((item, index) =>

                            <a id={"item" + (index + 1)} key={'k' + (index + 1)} className={index == 0 ? "active" : ""} href="#" onClick={that.goPagination}>
                                <svg className="circle" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <circle cx="15" cy="15" r="14.75" fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth=".7"/>
                                </svg>
                                <div/>
                            </a>

                        )}

                        </div>
                    </div>
                </div>
            );

        }

        function MyCursor() {

            return(
                <Cursor />
            )

        }

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/media',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
                <SimpleReactLightbox>
                    <div className="wrapper" ref={this.container}>
                        <div id="mediaContainer" className="container">
                            <div id="mediaTabsHeader" className="col-row">
                                <div className="col-4-12 col-xs-6-12">
                                    <div id="mediaTabs">
                                        <a href="#photoTab" className="active" onClick={this.changeTab}>{this.photo}</a>
                                        <a href="#videoTab" onClick={this.changeTab}>{this.video}</a>
                                        <span id="photoTabSelector" className="mediaSelector"></span>
                                    </div>
                                </div>
                                <div className="col-2-12 hidden-xs"></div>
                                <div className="col-4-12 col-xs-6-12 pullRight">
                                    <h1 id="mediaTitle" ref={h1 => this.mediaTitle = h1}>{this.media}</h1>
                                </div>
                            </div>
                            <div id="mediaTabsContainer">

                                <div className="mediaContainer active fullHeight" id="photoTab">

                                    <div className="col-row fullHeight">
                                        <div className="col-4-12 col-sm-2-12 hidden-xs"></div>
                                        <div className="col-7-12 col-sm-10-12 col-xs-12-12">
                                            <SRLWrapper options={optionPhoto}>
                                                {!mediaItems ? (
                                                    <span>Loading...</span>
                                                ) : (

                                                    <MySlider/>

                                                )}
                                            </SRLWrapper>
                                        </div>
                                        <div className="col-1-12 hidden-xs fullHeight">
                                        </div>
                                    </div>
                                </div>

                                <div className="mediaContainer" id="videoTab">
                                    <div className="col-row">
                                        <div className="col-3-12"></div>
                                        <div className="col-9-12"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MyCursor/>
                    </div>
                </SimpleReactLightbox>
            </DocumentMeta>
        );

    }

}