import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom'
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {getGPUTier} from 'detect-gpu';
import MobileDetect from "mobile-detect";

//Styles
import "./styles/normalize.css"
import "./styles/fonts.css"
import "./styles/main.css"

//Pages & Blocks
import {Home} from './pages/Home';
import {About} from "./pages/About";
import {Strategy} from "./pages/Strategy";
import {Contacts} from "./pages/Contacts";
import {Leadership} from "./pages/Leadership";
import {Geography} from "./pages/Geography";
import {Operation} from "./pages/Operation";
import {Media} from "./pages/Media";
import {News} from "./pages/News";
import {NewsDetail} from "./pages/NewsDetail";
import {Privacy} from "./pages/Privacy";
import {Pravi} from "./components/Pravi";
import {NotFound} from "./pages/NotFound";

//Components
import Waves from "./components/Waves";
import Distortion from "./components/Distortion";
import {Transitions} from "./components/Transitions"

class App extends Component {

    constructor() {
        super();
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.GPUTier = getGPUTier().tier;
        this.goodGPU = false;
        if(this.GPUTier == 'GPU_DESKTOP_TIER_3' || this.GPUTier == 'GPU_DESKTOP_TIER_2'){
            this.goodGPU = true;
        }
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.turnPhoneText = this.language == 'en' ? 'Turn your phone for a comfortable<br/> viewing experience. ' : 'Поверните телефон для<br/> комфортного просмотра';
        // Internet Explorer 6-11
        this.isIE = /*@cc_on!@*/false || !!document.documentMode;
    }

    pageFadeOut(){

        let header = document.querySelector('header');
        if (header && header.classList.contains('gradient')) {
            header.classList.remove('gradient');
        }

        //Burger & Menu : close menu
        let  burger = document.querySelector('.burger');
        if (burger && burger.classList.contains('active')) {
            burger.classList.remove('active');
        }

        //Hidden Menu
        let  hiddenMenu = document.querySelector('#hiddenMenu');
        if (hiddenMenu && !hiddenMenu.classList.contains('hidden')) {
            hiddenMenu.removeAttribute('style');
            hiddenMenu.classList.add('hidden');
        }

        //we need remove the height`s styles in body
        document.body.removeAttribute('style');

    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {

            let size;

            let prev = prevProps.location.pathname.includes('/news/') ? 'news_detail' : prevProps.location.pathname.split("/").pop();
            let next = this.props.location.pathname.includes('/news/') ? 'news_detail' : this.props.location.pathname.split("/").pop();

            let prevImg = (prev === '' || prev === 'en') ? 'bg_home' : 'bg_' + prev;
            let nextImg = (next === '' || next === 'en') ? 'bg_home' : 'bg_' + next;

            if(this.isMobile){

                size = 'xss';

                if(window.innerWidth > 720){
                    size = 'xs';
                }

                if(window.innerWidth > window.innerHeight){
                    size = 'lg';
                }

            } else {

                size = 'sm';

                if(window.innerWidth > 1440 && window.innerWidth < 1921){

                    size = 'md';

                } else if(window.innerWidth > 1920){

                    size = 'lg';

                }

            }

            let fadeOut = false;

            if(this.goodGPU && !this.isMobile){
                fadeOut = true;
            }

            if(this.isIE){
               if(nextImg == 'bg_operation'){
                   nextImg = 'bg_operation2';
               }
               prevImg = size + '/' + prevImg + '.png';
               nextImg = size + '/' + nextImg + '.png';
               document.body.style.background = 'url(/' + nextImg + ') no-repeat';
               document.body.style.backgroundSize = 'cover';
               document.body.style.backgroundAttachment = 'fixed';
            } else {
                prevImg = size + '/' + prevImg + '.png';
                nextImg = size + '/' + nextImg + '.png';
                Transitions(prevImg, nextImg, fadeOut, 'morph', this.isMobile);
            }

        }

    }

    pageFadeIn(){

    }

    componentDidMount() {

        //Preloader
        let preloader = document.getElementById('preloader'),
            main = document.querySelector('.loading'),
            orangeCursor = document.querySelector('.circleCursor .orange'),
            loadingCircle = document.getElementById('secondCircle'),
            preloadIcon = document.getElementById('preloadIcon');

        if(orangeCursor){

            for (let i = 0; i <= 100; i+=5) {
                setTimeout(function(y) {
                    orangeCursor.setAttribute('loading-data', i);
                    if(i == 100){
                        preloader.style.opacity = 0;
                        main.classList.remove('loading');
                        setTimeout(function () {
                            main.removeChild(preloader);
                            document.querySelector('.circleCursor').removeChild(preloadIcon);
                            //preloader.remove();
                            //preloadIcon.remove();
                        }, 3000);
                    }
                }, i * 20, i);
            }

            for(let i=245;i >= 40; i--){
                setTimeout(function() {
                    loadingCircle.style.strokeDashoffset = 285 - i;
                }, i * 8, i);
            }

        } else {

            setTimeout(function () {

                let preloader = document.getElementById('preloader'),
                    main = document.querySelector('.loading'),
                    orangeCursor = document.querySelector('.circleCursor .orange'),
                    loadingCircle = document.getElementById('secondCircle'),
                    preloadIcon = document.getElementById('preloadIcon');

                for (let i = 0; i <= 100; i+=5) {
                    setTimeout(function(y) {
                        orangeCursor.setAttribute('loading-data', i);
                        if(i == 100){
                            preloader.style.opacity = 0;
                            main.classList.remove('loading');
                            setTimeout(function () {
                                main.removeChild(preloader);
                                document.querySelector('.circleCursor').removeChild(preloadIcon);
                                //preloader.remove();
                                //preloadIcon.remove();
                            }, 3000);
                        }
                    }, i * 20, i);
                }

                for(let i=245;i >= 40; i--){
                    setTimeout(function() {
                        loadingCircle.style.strokeDashoffset = 285 - i;
                    }, i * 8, i);
                }

            }, 1);

        }



        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        //let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        //document.documentElement.style.setProperty('--vh', `${vh}px`);

        // We listen to the resize event
        /*window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });*/


        //if(this.isMobile && (window.innerWidth > window.innerHeight)){
        //document.getElementById('mobileHorizontalOrientation').classList.remove('hidden');
        //}

        if(this.isMobile){
            window.addEventListener("orientationchange", function() {
                window.location.reload();
            });
        }

        //Fire only first time
        if(!this.goodGPU || this.isMobile || this.isIE){

            let cur,
                curImg,
                size;

            if(this.props.location.pathname.includes('/news/')){
                curImg = 'bg_news_detail';
            } else {
                cur  = this.props.location.pathname.split("/").pop();
                curImg = (cur === '' || cur === 'en') ? 'bg_home' : 'bg_' + cur;
            }

            if(this.isMobile){

                size = 'xss';

                if(window.innerWidth > 720){
                    size = 'xs';
                }

                if(window.innerWidth > window.innerHeight){
                    size = 'lg';
                }

            } else {

                size = 'sm';

                if(window.innerWidth > 1440 && window.innerWidth < 1921){

                    size = 'md';

                } else if(window.innerWidth > 1920){

                    size = 'lg';

                }

            }

            if(this.isIE) {

                if(curImg == 'bg_operation'){
                    curImg = 'bg_operation2';
                }
                curImg = size + '/' + curImg + '.png';
                document.body.style.background = 'url(/' + curImg + ') no-repeat';
                document.body.style.backgroundSize = 'cover';
                document.body.style.backgroundAttachment = 'fixed';

            } else {

                curImg = size + '/' + curImg + '.png';
                Transitions(curImg, curImg, false, 'morph', this.isMobile);

            }

        }

        let header = document.querySelector('header');

        window.addEventListener('scroll', function () {
            if (window.scrollY > 0) {
                header.classList.add('gradient');
            } else {
                header.classList.remove('gradient');
            }
        });

    }

    render() {

        console.log(this.GPUTier);

        const timeout = { enter: 800, exit: 800 };

        const locationClassName = this.props.location.pathname.includes('/news/') ? 'news_detail' : this.props.location.pathname.split("/").pop();

        let distortionBg = 'bg_home',
            size;

        if(locationClassName !== '' && locationClassName !== 'en'){
            distortionBg = 'bg_' + locationClassName;
        }

        if(this.isMobile){

            document.querySelector('html').classList.add('isMobile');

            document.body.addEventListener('touchstart touchmove touchend', function (e) {
                e.preventDefault();
            });

            size = 'xss';

            if(window.innerWidth > 720){
                size = 'xs';
            }

            if(window.innerWidth > window.innerHeight){
                size = 'lg';
            }

        } else {

            size = 'sm';

            if(window.innerWidth > 1440 && window.innerWidth < 1921){

                size = 'md';

            } else if(window.innerWidth > 1920){

                size = 'lg';

            }

        }

        distortionBg = size + '/' + distortionBg + '.png';

        document.body.className = locationClassName;

        return (
            <Route render={({location}) => (

                <main className="loading">
                    <div id="preloader"></div>
                    <Pravi language={this.language} />
                    <TransitionGroup component="div" className="fadePage">
                        <CSSTransition
                            key={location.key}
                            timeout={timeout}
                            classNames="fade"
                            onEnter={this.pageFadeOut}
                            onExited={this.pageFadeIn}
                        >
                            <Switch location={location}>
                                <Route path='/' exact component={Home} />

                                <Route path='/about' component={About} />

                                <Route path='/strategy' component={Strategy} />

                                <Route path='/news' exact component={News} />

                                <Route path='/news/:id' component={NewsDetail} />

                                <Route path='/contacts' component={Contacts} />

                                <Route path='/leadership' component={Leadership} />

                                <Route path='/operation' component={Operation} />

                                <Route path='/geography' component={Geography} />

                                <Route path='/media' component={Media} />

                                <Route path='/privacy' component={Privacy} />

                                <Route path="/404" component={NotFound} status={404} />

                                <Redirect to="/404" />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                    <Waves/>
                    {!this.isMobile && this.goodGPU && !this.isIE ? (
                        <Distortion imageBg={distortionBg} />
                    ) : (
                        ''
                    )}
                    <div id="mobileHorizontalOrientation" className="hidden">
                        <div id="turnIcon">
                            <img src="/rotate.svg" />
                            <p dangerouslySetInnerHTML={{__html:this.turnPhoneText}} />
                        </div>
                    </div>
                </main>

            )} />
        );

    }

}

export default App;
