import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';

import Cursor from "../components/Cursor"
import {changeBackground} from "../components/Distortion";
import {Transitions} from "../components/Transitions";
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";
import {getGPUTier} from 'detect-gpu';
import MobileDetect from "mobile-detect";
import {TimelineLite} from "gsap/all";

export class Leadership extends Component{

    constructor(props) {
        super(props);
        this.leadershipTitle = null;
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.GPUTier = getGPUTier().tier;
        this.goodGPU = false;
        if(this.GPUTier == 'GPU_DESKTOP_TIER_3' || this.GPUTier == 'GPU_DESKTOP_TIER_2'){
            this.goodGPU = true;
        }
        // Internet Explorer 6-11
        this.isIE = /*@cc_on!@*/false || !!document.documentMode;
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.lang = this.language == 'en' ? '/en' : '';
        this.changeTab = this.changeTab.bind(this); //bind function once

        this.chairman = this.language == 'en' ? 'Chairman of the board' : 'Председатель<br/> совета директоров';
        this.board = this.language == 'en' ? 'Board of Directors' : 'Совет директоров';
        this.managment = this.language == 'en' ? 'Management' : 'Менеджмент';

        this.pageUrl = 'https://bitrix.rusneftekhim.com/init?json=1&leadership=1&lang=' + this.language;
        this.state = {
            error: null,
            isLoaded: false,
            page: [],
            chairman: [],
            managment: [],
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    page: result.PAGE,
                    chairman: result.PEOPLE.chairman,
                    managment: result.PEOPLE.managment,
                });

                let peopleTween = new TimelineLite();
                document.querySelectorAll('.leadershipContainer.active .leadershipItem').forEach(function (item) {
                    let aboutHeight = item.querySelector('.leadershipItemAbout').offsetHeight;
                    peopleTween.add(TweenMax.fromTo(item, .8, {css: {opacity: 0, top: 20}}, {css: {opacity: 1, top: 0}}), "-=0.25");
                    peopleTween.add(TweenMax.fromTo(item.querySelector('.leadershipItemAbout'), .25, {css: {height: 0}}, {css: {height: aboutHeight}}, "-=0.4"));
                    peopleTween.add(TweenMax.fromTo([item.querySelector('.leadershipName'), item.querySelector('.leadershipPosition')], .35, {css: {opacity: 0, left: -100}}, {css: {opacity: 1, left: 0}}, "-=0.25"));
                })

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

        const splitLeadershipTitle = new SplitText(this.leadershipTitle, {type: "chars"});
        TweenMax.staggerFrom(
            splitLeadershipTitle.chars,
            1,
            {
                yPercent: 50,
                opacity: 0
            },
            0.15
        );

        if(this.language == 'en'){
            document.getElementById('leadershipTabs').classList.add('english');
        }

    }

    changeTab(e) {

        e.preventDefault();

        let size;

        if(this.isMobile){

            size = 'xss';

            if(window.innerWidth > 720){
                size = 'xs';
            }

            if(window.innerWidth > window.innerHeight){
                size = 'lg';
            }

        } else {

            size = 'sm';

            if(window.innerWidth > 1440 && window.innerWidth < 1921){

                size = 'md';

            } else if(window.innerWidth > 1920){

                size = 'lg';

            }

        }


        let clickedTarget = e.target,
            slider = document.getElementById('slider'),
            images = JSON.parse(slider.getAttribute('data-images')),
            leadershipTabs = document.querySelectorAll('#leadershipTabs a');

        let activeTabId = clickedTarget.getAttribute('href').substr(1),
            prevTabId = document.querySelector('#leadershipTabs .active').getAttribute('href').substr(1);

        if(activeTabId === 'chairmanTab'){
            document.body.classList.remove('noOrange');
        }else{
            document.body.classList.add('noOrange');
        }

        leadershipTabs.forEach(function (item) {

            item.classList.remove('active');

        });

        clickedTarget.classList.add('active');

        let leadershipSelector = document.querySelector('.leadershipSelector');

        leadershipSelector.id = activeTabId + 'Selector';


        let leadershipContainers = document.querySelectorAll('.leadershipContainer');

        leadershipContainers.forEach(function (item) {

            item.classList.remove('active');

        });

        document.getElementById(activeTabId).classList.add('active');

        let peopleTween = new TimelineLite();
        let peopleCounter = 0;
        document.querySelectorAll('.leadershipContainer.active .leadershipItem').forEach(function (item) {
            let aboutHeight = item.querySelector('.leadershipItemAbout').offsetHeight;
            peopleTween.add(TweenMax.fromTo(item, .8, {css: {opacity: 0, top: 20}}, {css: {opacity: 1, top: 0}}), "-=0.25");
            peopleTween.add(TweenMax.fromTo(item.querySelector('.leadershipItemAbout'), .25, {css: {height: 0}}, {css: {height: aboutHeight}}, "-=0.4"));
            peopleTween.add(TweenMax.fromTo([item.querySelector('.leadershipName'), item.querySelector('.leadershipPosition')], .35, {css: {opacity: 0, left: -100}}, {css: {opacity: 1, left: 0}}, "-=0.25"));
            peopleCounter++;
        })

        let prevId = 0;

        if(prevTabId === 'chairmanTab'){
            prevId = 0;
        } else if(prevTabId === 'boardTab'){
            prevId = 1;
        }else if(prevTabId === 'managmentTab'){
            prevId = 2;
        }

        let imgId = 0;

        if(activeTabId === 'chairmanTab'){
            imgId = 0;
        } else if(activeTabId === 'boardTab'){
            imgId = 1;
        }else if(activeTabId === 'managmentTab'){
            imgId = 2;
        }

        if(this.isIE){

            document.body.style.background = 'url(/' + size + '/' + images[prevId] + ') no-repeat';
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundAttachment = 'fixed';

        } else {

            if(!this.isMobile && this.goodGPU){

                Transitions(size + '/' + images[prevId],size + '/' + images[imgId],true,'morph');

                setTimeout(function () {
                    changeBackground(size + '/' + images[imgId]);
                }, 1000);

            } else {

                Transitions(size + '/' + images[prevId],size + '/' + images[imgId],false,'morph');

            }

        }

    }

    render() {

        const { page, chairman, managment } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/leadership',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return(
            <DocumentMeta {...meta}>
                <div className="wrapper">
                <div id="leadershipContainer" className="container">
                    <div className="col-row">
                        <div className="col-6-12 col-xs-12-12">
                            <h1 className="hidden visible-xs" ref={h1 => this.leadershipTitle = h1}>{page.NAME}</h1>
                            <div id="leadershipTabs">
                                <a href="#chairmanTab" className="active" onClick={this.changeTab} dangerouslySetInnerHTML={{__html:this.chairman}}/>
                                <a href="#boardTab" className="hidden" onClick={this.changeTab}>{this.board}</a>
                                <a href="#managmentTab" onClick={this.changeTab}>{this.managment}</a>
                                <span id="chairmanTabSelector" className="leadershipSelector" ></span>
                            </div>
                        </div>
                        <div className="col-5-12 hidden-xs pullRight">
                            <h1  id="leadershipTitle" ref={h1 => this.leadershipTitle = h1}>{page.NAME}</h1>
                        </div>
                    </div>
                    <div id="leadershipTabsContainer">

                        <div className="leadershipContainer active" id="chairmanTab">
                            {chairman.map(item =>
                                <div className="leadershipItem">
                                    <img src={'https://bitrix.rusneftekhim.com/upload/' + item.SUBDIR + '/' + item.FILE_NAME} alt={item.DESCRIPTION.replace(/<\/?[^>]+>/gi, '')}/>
                                    <div className="leadershipItemAbout" dangerouslySetInnerHTML={{__html:item.DESCRIPTION}} />
                                </div>
                            )}
                        </div>

                        <div className="leadershipContainer" id="boardTab">
                            <div className="leadershipItem">
                                <img src="manager.png" alt="Георгиев Алекс Андреевич"/>
                                <div className="leadershipItemAbout">
                                    <div className="leadershipName"><span>Георгиев</span><br/>Алекс Андреевич</div>
                                </div>
                            </div>
                            <div className="leadershipItem">
                                <img src="maslennikov.png" alt="Масленников Борис Алексеевич"/>
                                <div className="leadershipItemAbout">
                                    <div className="leadershipName"><span>Масленников</span><br/>Борис Алексеевич</div>
                                </div>
                            </div>
                            <div className="leadershipItem">
                                <img src="strukov.png" alt="Струков Вячеслав Алексеевич"/>
                                <div className="leadershipItemAbout">
                                    <div className="leadershipName"><span>Струков</span><br/>Вячеслав Алексеевич</div>
                                </div>
                            </div>
                            <div className="leadershipItem">
                                <img src="krasimir.jpg" alt="Дачев Красимир"/>
                                <div className="leadershipItemAbout">
                                    <div className="leadershipName"><span>Дачев</span><br/>Красимир</div>
                                </div>
                            </div>
                        </div>

                        <div className="leadershipContainer" id="managmentTab">
                            {managment.map(item =>
                                <div className="leadershipItem">
                                    <img src={'https://bitrix.rusneftekhim.com/upload/' + item.SUBDIR + '/' + item.FILE_NAME} alt={item.DESCRIPTION.replace(/<\/?[^>]+>/gi, '')}/>
                                    <div className="leadershipItemAbout" dangerouslySetInnerHTML={{__html:item.DESCRIPTION}} />
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                <div id="slider" data-images='["bg_leadership.png","bg_leadership.png","bg_leadership2.png"]'></div>
                <Cursor/>
            </div>
            </DocumentMeta>
        );

    }

}