import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Tween } from 'react-gsap';

const MouseFollowStyled = styled.div`
`;

const Circle = styled.div`
  position: fixed;
  background-color: #ff5900;
  width: 10px;
  height: 10px;
  left:-10px;
  top:-10px;
  border-radius: 100%;
  z-index: 20000;
  transform: scale(1);
`;

const Follow = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  width: 33px;
  height: 33px;
  left:-22px;
  top:-22px;
  border-radius: 100%;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
`;

class MouseFollowComponent extends PureComponent {

    state = {
        x: window.innerWidth/2,
        y: window.innerHeight/2,
        scaleCircle: 1,
        scaleFollow: 1,
        reload: false
    }

    tween;

    constructor(props) {
        super(props);
        this.mouseMove = this.mouseMove.bind(this);
        this.hoverFunc = this.hoverFunc.bind(this);
        this.unhoverFunc = this.unhoverFunc.bind(this);
        //this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    // refreshPage = () => {
    //     this.setState(
    //         {reload: true},
    //         () => this.setState({reload: false})
    //     )
    // }
    //
    // forceUpdateHandler(){
    //     this.forceUpdate();
    // };

    componentDidMount() {
        window.addEventListener('mousemove', this.mouseMove);

        let hoverables = document.querySelectorAll('a, .hoverable');
        for (let i=0; i < hoverables.length; i++){
            hoverables[i].addEventListener('mouseenter', this.hoverFunc);
            hoverables[i].addEventListener('mouseleave', this.unhoverFunc);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouseMove);
    }

    mouseMove({ clientX: x, clientY: y }) {
        this.setState((state) => {
            return {x, y};
            //return state;
        });
    }

    hoverFunc(event){
        this.setState((state) => {
            return {
                scaleCircle: 0,
                scaleFollow: 3,
            };
        });
    }

    unhoverFunc(event){
        this.setState((state) => {
            return {
                scaleCircle: 1,
                scaleFollow: 1,
            };
        });
    }

    render() {
        const { x, y } = this.state;

        return(
            <MouseFollowStyled className="circleCursor">
                <Tween to={{x: x, y: y, scale: this.state.scaleCircle}} duration={0.03} ref={ref => this.tween = ref}>
                    <Circle className="orange" />
                    <div id="preloadIcon">
                        <svg width="80" height="80">
                            <circle id="firstCircle" cx="40" cy="40" r="39"></circle>
                            <circle id="secondCircle" cx="40" cy="40" r="39"></circle>
                        </svg>
                    </div>
                </Tween>
                <Tween to={{x: x, y: y, scale: this.state.scaleFollow}} duration={0.25} ref={ref => this.tween = ref}>
                    <Follow className="white" />
                </Tween>
            </MouseFollowStyled>
        );
    }
}

export default MouseFollowComponent;