import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';

import Cursor from "../components/Cursor"
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";

export class About extends Component {

    constructor(props) {
        super(props);
        this.aboutTitle = null;
        this.aboutText = null;
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.lang = this.language == 'en' ? '/en' : '';
        this.pageUrl = this.language == 'ru' ? 'https://bitrix.rusneftekhim.com/init?json=1&page=about&lang=ru' : 'https://bitrix.rusneftekhim.com/init?json=1&page=about_en&lang=en';
        this.state = {
            error: null,
            isLoaded: false,
            page: []
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    page: result[0]
                });

                const splitAboutTitle = new SplitText(this.aboutTitle, {type: "chars"});
                TweenMax.staggerFrom(splitAboutTitle.chars, 1, {yPercent: 50, opacity: 0}, 0.15);

                const splitAboutText = new SplitText(this.aboutText, {type: "lines"});
                TweenMax.staggerFrom(splitAboutText.lines, 2, {opacity:0, yPercent:100}, 0.15);

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }

    render() {

        const { page } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/about',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
            <div className="wrapper">
                <div className="absoluteContainer container">
                    <div className="absoluteWrapper">
                        <div className="col-row">
                            <div className="col-8-12 col-sm-7-12 hidden-xs">
                            </div>
                            <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                <h1 id="aboutTitle" ref={h1 => this.aboutTitle = h1} dangerouslySetInnerHTML={{__html: page.NAME}} />
                            </div>
                        </div>
                        <div className="col-row">
                            <div className="col-1-2 col-sm-7-12 col-xs-12-12">
                                <div id="aboutText" className="aboutText" ref={div => this.aboutText = div} dangerouslySetInnerHTML={{__html: page.DESCRIPTION}} />
                            </div>
                        </div>
                    </div>
                </div>
                <Cursor/>
            </div>
            </DocumentMeta>
        );

    }

}