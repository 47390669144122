import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import {TimelineLite} from "gsap/all";
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";

import Cursor from "../components/Cursor"

export class Geography extends Component {

    constructor(props) {
        super(props);
        this.geographyTitle = null;
        this.geographyText = null;
        //this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.language = window.location.hostname == 'rusneftekhim.ru' ? 'ru' : 'en';
        this.lang = this.language == 'en' ? '/en' : '';
        this.pageUrl = this.language == 'ru' ? 'https://bitrix.rusneftekhim.com/init?json=1&page=geography&lang=ru' : 'https://bitrix.rusneftekhim.com/init?json=1&page=geography_en&lang=en';
        this.state = {
            error: null,
            isLoaded: false,
            page: []
        };
    }

    componentDidMount() {

        var geographyTabs = document.querySelectorAll('#geographyTabs a');

        function goTab(tab) {

            let activeTabId = tab.getAttribute('href').substr(1),
                activeCity,
                inactiveCity;

            let geographySelector = document.querySelector('.geographySelector');
            geographySelector.id = activeTabId + 'Selector';

            let TL = new TimelineLite();

            if(activeTabId === 'olikuminskoyeTab'){
                activeCity = 'olikuminskoye';
                inactiveCity = 'urengoyskoye';
            } else {
                activeCity = 'urengoyskoye';
                inactiveCity = 'olikuminskoye';
            }

            //hide
            TL.fromTo('#' + inactiveCity + 'Tab .geographyDescription', 0.35, {opacity: 1, x: 0}, {opacity: 0, x:-100}, "0");
            document.querySelectorAll('#' + inactiveCity + 'Tab .geographyNumbs li').forEach(function (item) {
                TL.fromTo(item, 0.25, {opacity: 1, x: 0}, {opacity: 0, x:100, delay: 0.1}, "-=0.15");
            });
            TL.to('#' + inactiveCity + 'Anchor', 0.1, {className: "-=active"}, "-=0.6");
            TL.to('#' + inactiveCity, 0.1, {className: "-=active"}, "-=0.1");
            TL.to('#' + inactiveCity + 'Tab', 0.1, {className: "-=active"}, "-=0.2");
            //show
            TL.to('#' + activeCity, 0.1, {className: "+=active"}, "-=0.0");
            TL.to('#' + activeCity + 'Anchor', 0.1, {className: "+=active"}, "-=1.1");
            TL.to('#' + activeCity + 'Tab', 0.1, {className: "+=active"}, "-=0.2");
            TL.fromTo('#' + activeCity + 'Tab .geographyDescription', 0.25, {opacity: 0, x:-100}, {opacity: 1, x:0}, "+=0");
            document.querySelectorAll('#' + activeCity + 'Tab .geographyNumbs li').forEach(function (item) {
                TL.fromTo(item, 0.2, {opacity: 0, x: 100}, {opacity: 1, x:0, delay: 0.1}, "-=0.1");
            });

        }

        for(let i = 0; i < geographyTabs.length; i++){

            geographyTabs[i].addEventListener('click', function (e) {

                e.preventDefault();

                goTab(this);

            });

        }

        document.querySelector('svg #urengoyskoye').addEventListener('click', function () {

            let tabUrengoj = document.getElementById('urengoyskoyeAnchor');

            goTab(tabUrengoj);

        });

        document.querySelector('svg #olikuminskoye').addEventListener('click', function () {

            let tabOluminsk = document.getElementById('olikuminskoyeAnchor');

            goTab(tabOluminsk);

        });

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    page: result[0]
                });

                const splitGeographyTitle = new SplitText(this.geographyTitle, {type: "chars"});
                TweenMax.staggerFrom(splitGeographyTitle.chars, 1, {yPercent: 50, opacity: 0}, 0.15);

                const splitGeographyText = new SplitText(this.geographyText, {type: "lines"});
                TweenMax.staggerFrom(splitGeographyText.lines, 2, {opacity:0, yPercent:100}, 0.15);

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }

    render() {

        const { page } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/geography',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
                <div className="wrapper">
                <div id="geographyContainer" className="absoluteContainer container">
                    <div className="absoluteWrapper">
                        <div id="geographyTabsHeader" className="col-row">
                            <div className="col-8-12 col-sm-7-12 col-xs-12-12">
                                <div id="geographyTabs">
                                    <a id="urengoyskoyeAnchor" className="active"
                                       href="#urengoyskoyeTab">Уренгойское<br/> месторождение</a>
                                    <a id="olikuminskoyeAnchor" href="#olikuminskoyeTab">Оликуминское<br/> месторождение</a>
                                    <span id="urengoyskoyeTabSelector" className="geographySelector"></span>
                                </div>
                            </div>
                            <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                <h1 ref={h1 => this.geographyTitle = h1} dangerouslySetInnerHTML={{__html: page.NAME}} />
                            </div>
                        </div>
                        <div id="geographyTabsContainer">

                            <div id="geographyMap" className="hidden">
                                <svg x="0px" y="0px" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
                                     viewBox="0 0 546 739">
                                    <g id="map">
                                        <text transform="matrix(1 0 0 1 275.2021 436)" className="st0 st1 st2"></text>
                                        <g>
                                            <polygon className="st3" points="375.31,1 373.63,21.23 378.49,33.51 364.07,27.74 342.43,44.33 316.47,77.49 296.28,96.96
            266,104.17 250.13,119.31 266,140.22 248.7,171.23 224.9,178.44 224.18,189.26 245.1,210.89 237.88,231.08 241.49,244.05
            249.41,255.59 222.74,280.11 214.09,266.41 206.87,235.4 178.71,190.22 162.59,203.65 172.06,220.49 172.43,221.15 172.06,221.81
            152.31,256.92 159.93,281.97 160.12,282.56 159.77,283.08 147.53,302 147.28,302.39 146.83,302.54 127.15,309.1 114.04,324.4
            113.66,324.84 113.08,324.86 63.46,327.08 38.2,347.93 37.85,348.23 37.37,348.23 21.13,348.49 19.67,390.68 19.66,391.09
            19.42,391.42 0,418.76 12.58,445.32 60.4,448.94 60.8,448.97 61.12,449.22 83.95,466.89 84.44,467.28 84.47,467.92 85.18,490.91
            93.24,498.3 147.99,501.22 148.6,501.25 148.96,501.73 165.9,523.07 166.18,523.42 166.19,523.86 167.65,558.78 176.96,570.95
            177.25,571.34 177.24,571.81 176.54,594.72 212.72,594.72 213.24,594.72 213.61,595.08 246.59,625.11 292.03,644.91
            292.42,645.08 292.64,645.44 309.42,673.14 339.06,691.22 369.16,694.09 393.78,675.99 394.67,675.32 395.49,676.08
            417.91,697.05 440.93,696.34 441.66,696.31 442.07,696.93 459.38,722.15 468.31,722.84 468.81,722.89 469.16,723.24
            485.76,739.84 520.09,696.22 522.25,668.09 522.29,667.7 522.5,667.4 545.22,636.02 541.02,610.76 515.1,591.33 514.44,590.83
            514.58,590.02 521.08,553.27 503.85,529.57 503.55,529.16 503.6,528.65 509.03,477.59 487.42,449.49 486.98,448.92 487.2,448.25
            495.82,422.39 487.24,401.99 486.64,400.57 488.13,400.17 516.01,392.67 527.81,362.7 521.63,327.37 492.47,320.08 492.01,319.96
            491.73,319.59 464.93,284.22 464.54,283.71 464.7,283.09 470.06,261.65 470.22,261.02 470.82,260.76 510.94,242.8 514.96,226.75
            498.01,190.75 497.83,190.37 497.91,189.94 502.19,166.36 502.37,165.43 503.29,165.28 520.84,162.35 504.44,127.52
            504.34,127.32 504.32,127.07 504.06,124.23 489.53,125.08 482.31,151.04 458.52,129.41 452.03,151.76 477.27,179.16
            478.72,202.95 432.57,161.85 431.13,135.9 450.59,112.1 453.47,73.89 445.54,77.49 436.17,103.45 421.75,116.43 406.61,120.76
            392.19,142.39 392.9,199.35 357.57,254.87 359.74,288.76 395.8,288.76 425.36,324.09 425.36,357.98 404.44,383.22 410.21,394.76
            426.8,404.13 395.8,411.34 387.14,378.89 410.21,338.51 400.12,308.23 364.79,303.18 345.31,321.93 343.16,364.47 310.7,397.64
            281.14,408.46 260.95,430.09 193.18,388.27 193.18,368.07 238.6,388.98 262.4,379.62 300.61,355.1 326.57,303.18 322.25,275.06
            366.23,170.51 371.27,152.48 366.23,128.69 395.07,101.29 400.84,63.08 378.49,33.51 401.45,30.51 398.08,2.69 		"/>
                                            <g>
                                                <path className="st0" d="M115.55,392.75c0-2.65,1.47-4.34,3.77-4.34c1.76,0,3.16,1.12,3.42,2.72h-0.81c-0.26-1.16-1.32-1.97-2.6-1.97
                c-1.79,0-2.95,1.4-2.95,3.6c0,2.23,1.15,3.64,2.95,3.64c1.32,0,2.32-0.69,2.59-1.8h0.81c-0.32,1.59-1.61,2.54-3.41,2.54
                C117.01,397.13,115.55,395.43,115.55,392.75z M124.38,395.31c0-1.04,0.78-1.68,2.19-1.77l1.9-0.12v-0.53
                c0-0.88-0.56-1.41-1.51-1.41c-0.9,0-1.47,0.44-1.61,1.18h-0.75c0.09-1.09,0.98-1.86,2.37-1.86s2.26,0.78,2.26,2.02V397h-0.74
                v-1.16h-0.02c-0.34,0.76-1.17,1.26-2.08,1.26C125.2,397.1,124.38,396.38,124.38,395.31z M128.47,394.71v-0.67l-1.8,0.12
                c-0.96,0.06-1.49,0.48-1.49,1.15c0,0.66,0.57,1.12,1.39,1.12C127.63,396.43,128.47,395.68,128.47,394.71z M131.13,397.05
                c-0.18,0-0.3-0.04-0.35-0.06v-0.7c0.04,0.01,0.15,0.05,0.31,0.05c0.63,0,0.86-0.81,0.94-1.99c0.04-0.35,0.22-3.11,0.23-3.44h3.9
                V397h-0.77v-5.45h-2.44c-0.02,0.32-0.17,2.48-0.2,2.82C132.64,395.95,132.31,397.05,131.13,397.05z M143.31,395.27
                c-0.18,1.05-1.17,1.83-2.51,1.83c-1.71,0-2.77-1.2-2.77-3.13c0-1.89,1.07-3.16,2.72-3.16c1.62,0,2.64,1.19,2.64,3.01v0.33h-4.56
                v0.05c0,1.38,0.78,2.23,1.99,2.23c0.92,0,1.56-0.48,1.73-1.15H143.31z M138.83,393.53h3.76c-0.02-1.2-0.75-2.04-1.85-2.04
                C139.65,391.49,138.89,392.32,138.83,393.53z M147.16,394.5l-1.68,2.5h-0.87l2.14-3.06l-2.12-3.03h0.88l1.67,2.44h0.02l1.66-2.44
                h0.87l-2.13,3l2.11,3.09h-0.87l-1.67-2.5H147.16z M151.01,395.31c0-1.04,0.78-1.68,2.19-1.77l1.9-0.12v-0.53
                c0-0.88-0.56-1.41-1.51-1.41c-0.9,0-1.47,0.44-1.61,1.18h-0.75c0.09-1.09,0.98-1.86,2.37-1.86s2.26,0.78,2.26,2.02V397h-0.74
                v-1.16h-0.02c-0.34,0.76-1.17,1.26-2.08,1.26C151.83,397.1,151.01,396.38,151.01,395.31z M155.1,394.71v-0.67l-1.8,0.12
                c-0.96,0.06-1.49,0.48-1.49,1.15c0,0.66,0.57,1.12,1.39,1.12C154.26,396.43,155.1,395.68,155.1,394.71z M163.44,393.95
                c0,1.9-1.05,3.15-2.58,3.15c-0.98,0-1.75-0.56-2.07-1.35h-0.02v3.27h-0.77v-8.11h0.73v1.29h0.02c0.33-0.81,1.11-1.39,2.1-1.39
                C162.38,390.81,163.44,392.06,163.44,393.95z M162.65,393.95c0-1.48-0.76-2.46-1.94-2.46c-1.15,0-1.95,1-1.95,2.46
                c0,1.47,0.79,2.46,1.95,2.46C161.89,396.41,162.65,395.44,162.65,393.95z M164.53,398.61v-2.26h0.49
                c0.46-0.3,0.66-1.11,0.74-2.01c0.03-0.35,0.22-3.11,0.24-3.44h4.08v5.45h0.98v2.26h-0.73V397h-5.08v1.61H164.53z M169.31,391.55
                h-2.63c-0.02,0.32-0.17,2.48-0.21,2.82c-0.06,0.81-0.22,1.54-0.54,1.95v0.04h3.38V391.55z"/>
                                                <path className="st4"
                                                      d="M139,377c0-2.21,1.79-4,4-4s4,1.79,4,4s-1.79,4-4,4S139,379.21,139,377z"/>
                                            </g>
                                            <g>
                                                <circle className="st4" cx="337.67" cy="435" r="4"/>
                                                <path className="st0" d="M242.75,438v-3.97h-4.95V438h-0.8v-8.46h0.8v3.76h4.95v-3.76h0.8V438H242.75z M245.52,434.95
                c0-1.94,1.13-3.15,2.78-3.15c1.66,0,2.78,1.21,2.78,3.15c0,1.93-1.12,3.15-2.78,3.15S245.52,436.89,245.52,434.95z
                 M250.29,434.95c0-1.55-0.8-2.46-1.99-2.46s-1.99,0.91-1.99,2.46s0.79,2.46,1.99,2.46S250.29,436.5,250.29,434.95z
                 M252.91,431.91h2.46c1.14,0,1.84,0.58,1.84,1.53c0,0.67-0.45,1.24-1.07,1.35v0.04c0.79,0.09,1.36,0.63,1.36,1.44
                c0,1.09-0.79,1.73-2.1,1.73h-2.49V431.91z M253.68,432.54v1.99h1.49c0.83,0,1.29-0.36,1.29-1.03c0-0.61-0.42-0.96-1.18-0.96
                H253.68z M253.68,435.16v2.2h1.69c0.9,0,1.38-0.38,1.38-1.11s-0.54-1.09-1.52-1.09H253.68z M259.35,431.91h0.77v2.07h1.32
                c1.34,0,2.21,0.77,2.21,2.01s-0.87,2.02-2.21,2.02h-2.09V431.91z M260.12,434.62v2.74h1.31c0.88,0,1.45-0.54,1.45-1.37
                s-0.57-1.37-1.45-1.37H260.12z M264.56,431.91h0.77V438h-0.77V431.91z M267.46,438v-6.09h0.77v4.9h0.04l3.39-4.9h0.77V438h-0.77
                v-4.9h-0.04l-3.4,4.9H267.46z M268.92,429.28c0,0.69,0.45,1.19,1.17,1.19c0.71,0,1.17-0.5,1.17-1.19h0.59c0,1-0.7,1.67-1.76,1.67
                c-1.06,0-1.76-0.67-1.76-1.67H268.92z M278.23,437.99v-0.73c0.08,0.02,0.24,0.05,0.44,0.05c0.69,0,1.11-0.45,1.49-1.21
                l-2.94-6.56h0.86l2.46,5.65h0.07l2.36-5.65h0.84l-2.65,6.12c-0.72,1.67-1.31,2.4-2.49,2.4
                C278.46,438.06,278.29,438.02,278.23,437.99z M290.56,434.95c0,1.9-1.05,3.15-2.58,3.15c-0.98,0-1.75-0.56-2.07-1.35h-0.02v3.27
                h-0.77v-8.11h0.73v1.29h0.02c0.33-0.81,1.11-1.39,2.1-1.39C289.5,431.81,290.56,433.06,290.56,434.95z M289.77,434.95
                c0-1.48-0.76-2.46-1.94-2.46c-1.15,0-1.95,1-1.95,2.46c0,1.47,0.79,2.46,1.95,2.46C289,437.41,289.77,436.44,289.77,434.95z
                 M297.38,436.27c-0.18,1.05-1.17,1.83-2.51,1.83c-1.71,0-2.77-1.2-2.77-3.13c0-1.89,1.07-3.16,2.72-3.16
                c1.62,0,2.64,1.19,2.64,3.01v0.33h-4.56v0.05c0,1.38,0.78,2.23,1.99,2.23c0.92,0,1.56-0.48,1.73-1.15H297.38z M292.9,434.53h3.76
                c-0.02-1.2-0.75-2.04-1.85-2.04C293.72,432.49,292.96,433.32,292.9,434.53z M303.41,435.16h-3.35V438h-0.77v-6.09h0.77v2.61h3.35
                v-2.61h0.77V438h-0.77V435.16z M307.09,432.55V438h-0.77v-6.09h3.88v0.64H307.09z M311.38,434.95c0-1.94,1.13-3.15,2.78-3.15
                c1.66,0,2.78,1.21,2.78,3.15c0,1.93-1.12,3.15-2.78,3.15S311.38,436.89,311.38,434.95z M316.15,434.95c0-1.55-0.8-2.46-1.99-2.46
                s-1.99,0.91-1.99,2.46s0.79,2.46,1.99,2.46S316.15,436.5,316.15,434.95z M318.77,438v-6.09h0.77v4.9h0.04l3.39-4.9h0.77V438
                h-0.77v-4.9h-0.04l-3.4,4.9H318.77z M320.23,429.28c0,0.69,0.45,1.19,1.17,1.19c0.71,0,1.17-0.5,1.17-1.19h0.59
                c0,1-0.7,1.67-1.76,1.67c-1.06,0-1.76-0.67-1.76-1.67H320.23z"/>
                                            </g>
                                            <path className="st0" d="M283.2,539h-0.72l3.3-4.86c-1.7-0.25-2.89-1.38-2.89-3.16c0-1.99,1.4-3.25,3.62-3.25h3.64V539h-0.62v-4.78
            h-3.09L283.2,539z M289.53,528.3h-2.92c-1.93,0-3.09,1.01-3.09,2.68c0,1.66,1.16,2.67,3.09,2.67h2.92V528.3z M303.17,539v-9.99
            h-0.03l-4.34,9.97h-0.59l-4.34-9.97h-0.03V539h-0.59v-11.27h0.69l4.55,10.47h0.05l4.54-10.47h0.7V539H303.17z M313.15,535.24
            h-5.07l-1.4,3.76h-0.67l4.3-11.27h0.63l4.29,11.27h-0.66L313.15,535.24z M308.29,534.69h4.67l-2.32-6.16h-0.04L308.29,534.69z
             M324.76,539v-10.7h-5.03l-0.43,7.03c-0.13,2.18-0.67,3.74-2.26,3.74c-0.23,0-0.41-0.06-0.45-0.07v-0.57
            c0.02,0.01,0.2,0.06,0.42,0.06c1.13,0,1.57-1.29,1.68-3.22l0.45-7.55h6.23V539H324.76z M338.03,533.36c0,3.56-1.95,5.8-5.02,5.8
            s-5.02-2.24-5.02-5.8c0-3.55,1.95-5.8,5.02-5.8S338.03,529.81,338.03,533.36z M328.62,533.36c0,3.2,1.71,5.23,4.39,5.23
            s4.39-2.03,4.39-5.23c0-3.19-1.71-5.22-4.39-5.22C330.32,528.14,328.62,530.17,328.62,533.36z M340.42,534.75v-0.56h5.13v0.56
            H340.42z M356.13,539v-5.51H349V539h-0.62v-11.27H349v5.2h7.13v-5.2h0.62V539H356.13z M366.41,539h-6.57v-11.27h6.57v0.57h-5.95
            v4.64h5.67v0.56h-5.67v4.93h5.95V539z M376.85,539v-5.51h-7.13V539h-0.62v-11.27h0.62v5.2h7.13v-5.2h0.62V539H376.85z M387.12,539
            h-6.57v-11.27h6.57v0.57h-5.95v4.64h5.67v0.56h-5.67v4.93h5.95V539z M398.9,541.23V539h-9.09v-11.27h0.62v10.7h6.98v-10.7h0.62
            v10.7h1.46v2.8H398.9z M409.5,539h-0.89l-6.27-5.61h-0.04V539h-0.62v-11.27h0.62v5.3h0.04l5.88-5.3h0.84l-6.08,5.45L409.5,539z
             M419.59,528.76h-0.05l-7.2,10.24h-0.61v-11.27h0.61v10.25h0.06l7.2-10.25h0.62V539h-0.62V528.76z M431.15,528.76h-0.05L423.9,539
            h-0.61v-11.27h0.61v10.25h0.06l7.2-10.25h0.62V539h-0.62V528.76z M425.92,525.02c0,0.88,0.66,1.42,1.61,1.42s1.61-0.54,1.61-1.42
            h0.5c0,1.16-0.87,1.84-2.11,1.84s-2.11-0.69-2.11-1.84H425.92z M307.95,559.24h-5.07l-1.4,3.76h-0.67l4.3-11.27h0.63l4.29,11.27
            h-0.66L307.95,559.24z M303.08,558.69h4.67l-2.32-6.16h-0.04L303.08,558.69z M312.27,563v-11.27h3.76c1.96,0,3.29,1.11,3.29,2.74
            c0,1.34-0.88,2.38-2.22,2.64v0.05c1.59,0.17,2.81,1.2,2.81,2.84c0,1.84-1.44,3.01-3.8,3.01H312.27z M312.88,556.92h2.9
            c1.77,0,2.91-0.92,2.91-2.41c0-1.36-1.08-2.23-2.76-2.23h-3.05V556.92z M316.05,562.44c2.03,0,3.23-0.95,3.23-2.46
            c0-1.55-1.26-2.5-3.4-2.5h-3v4.96H316.05z M325.21,563v-10.7h-3.95v-0.57h8.52v0.57h-3.95V563H325.21z M340.87,557.36
            c0,3.56-1.95,5.8-5.02,5.8s-5.02-2.24-5.02-5.8c0-3.55,1.95-5.8,5.02-5.8S340.87,553.81,340.87,557.36z M331.46,557.36
            c0,3.2,1.71,5.23,4.39,5.23s4.39-2.03,4.39-5.23c0-3.19-1.71-5.22-4.39-5.22C333.16,552.14,331.46,554.17,331.46,557.36z
             M351.23,563v-5.51h-7.13V563h-0.62v-11.27h0.62v5.2h7.13v-5.2h0.62V563H351.23z M364.5,557.36c0,3.56-1.95,5.8-5.02,5.8
            s-5.02-2.24-5.02-5.8c0-3.55,1.95-5.8,5.02-5.8S364.5,553.81,364.5,557.36z M355.09,557.36c0,3.2,1.71,5.23,4.39,5.23
            s4.39-2.03,4.39-5.23c0-3.19-1.71-5.22-4.39-5.22C356.79,552.14,355.09,554.17,355.09,557.36z M377.04,563v-9.99h-0.03l-4.34,9.97
            h-0.59l-4.34-9.97h-0.03V563h-0.59v-11.27h0.69l4.55,10.47h0.05l4.54-10.47h0.7V563H377.04z M388.47,563v-5.51h-7.13V563h-0.62
            v-11.27h0.62v5.2h7.13v-5.2h0.62V563H388.47z M392.17,551.73h0.62v4.59h3.02c2.19,0,3.63,1.31,3.63,3.34s-1.43,3.34-3.63,3.34
            h-3.64V551.73z M395.71,562.43c1.91,0,3.1-1.06,3.1-2.77c0-1.7-1.2-2.77-3.1-2.77h-2.92v5.55H395.71z M401.19,563v-11.27h0.62V563
            H401.19z M412.76,552.76h-0.05L405.5,563h-0.61v-11.27h0.61v10.25h0.06l7.2-10.25h0.62V563h-0.62V552.76z M407.52,549.02
            c0,0.88,0.66,1.42,1.61,1.42s1.61-0.54,1.61-1.42h0.5c0,1.16-0.87,1.84-2.11,1.84s-2.11-0.69-2.11-1.84H407.52z M343.35,581.36
            c0,3.56-1.95,5.8-5.02,5.8s-5.02-2.24-5.02-5.8c0-3.55,1.95-5.8,5.02-5.8S343.35,577.81,343.35,581.36z M333.94,581.36
            c0,3.2,1.71,5.23,4.39,5.23s4.39-2.03,4.39-5.23c0-3.19-1.71-5.22-4.39-5.22C335.64,576.14,333.94,578.17,333.94,581.36z
             M353.78,587h-0.89l-6.27-5.61h-0.04V587h-0.62v-11.27h0.62v5.3h0.04l5.88-5.3h0.84l-6.08,5.45L353.78,587z M359.6,575.73
            c2.19,0,3.62,1.33,3.62,3.37s-1.43,3.36-3.63,3.36h-2.98V587H356v-11.27H359.6z M356.62,581.89h2.88c1.9,0,3.09-1.06,3.09-2.8
            c0-1.74-1.19-2.8-3.09-2.8h-2.88V581.89z M366.06,586.99v-0.57c0.05,0.02,0.24,0.07,0.54,0.07c1.01,0,1.59-0.92,2.17-2.19
            l-3.99-8.58h0.68l3.61,7.85h0.06l3.41-7.85h0.66l-3.66,8.3c-0.81,1.87-1.48,3.05-2.94,3.05
            C366.29,587.07,366.1,587.01,366.06,586.99z M375.41,587v-11.27h6.44v0.57h-5.82V587H375.41z"/>
                                        </g>
                                    </g>
                                    <g id="olikuminskoye" className="hoverable hidden">
                                        <g>
                                            <path className="st6" d="M408.34,358.78c0,2.68-1.5,4.37-3.9,4.37s-3.9-1.68-3.9-4.37s1.5-4.37,3.9-4.37S408.34,356.09,408.34,358.78z
             M401.63,358.78c0,2.08,1.08,3.39,2.81,3.39s2.81-1.31,2.81-3.39c0-2.09-1.08-3.4-2.81-3.4S401.63,356.69,401.63,358.78z
             M410,363.05c-0.18,0-0.29-0.04-0.36-0.06v-0.92c0.05,0.02,0.16,0.05,0.31,0.05c0.6,0,0.83-0.71,0.91-1.83l0.23-3.38h3.99V363
            h-1.01v-5.26H412l-0.19,2.57C411.69,361.99,411.29,363.05,410,363.05z M417.2,363v-6.09h1.01v4.54h0.05l3.05-4.54h1.01V363h-1.01
            v-4.54h-0.05l-3.05,4.54H417.2z M429.44,363h-1.31l-2.63-2.93h-0.05V363h-1.01v-6.09h1.01v2.7h0.05l2.6-2.7h1.26l-2.85,2.88
            L429.44,363z M430.93,365.24v-0.86c0.11,0.02,0.27,0.03,0.4,0.03c0.57,0,0.88-0.28,1.12-0.98l0.14-0.43l-2.25-6.1h1.09l1.69,5.07
            h0.02l1.69-5.07h1.07l-2.4,6.52c-0.52,1.42-1.03,1.85-2.13,1.85C431.25,365.28,431.04,365.27,430.93,365.24z M437.5,363v-6.09
            h1.24l2.17,4.75h0.05l2.18-4.75h1.19V363h-1.01v-4.32h-0.04l-1.97,4.15h-0.78l-1.97-4.15h-0.04V363H437.5z M446.45,363v-6.09h1.01
            v4.54h0.05l3.05-4.54h1.01V363h-1.01v-4.54h-0.05l-3.05,4.54H446.45z M457.69,360.26h-3.01V363h-1.01v-6.09h1.01v2.51h3.01v-2.51
            h1.01V363h-1.01V360.26z M464.87,358.78c-0.18-0.59-0.67-1.08-1.54-1.08c-1.06,0-1.76,0.9-1.76,2.26c0,1.39,0.71,2.26,1.77,2.26
            c0.81,0,1.34-0.4,1.54-1.07h1.01c-0.2,1.15-1.18,1.97-2.55,1.97c-1.69,0-2.8-1.21-2.8-3.15c0-1.9,1.1-3.15,2.79-3.15
            c1.47,0,2.37,0.94,2.55,1.98H464.87z M472.64,363h-1.31l-2.63-2.93h-0.05V363h-1.01v-6.09h1.01v2.7h0.05l2.6-2.7h1.26l-2.85,2.88
            L472.64,363z M473.77,359.95c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15
            C474.91,363.11,473.77,361.89,473.77,359.95z M478.38,359.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26
            S478.38,361.39,478.38,359.95z M486.29,361.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13
            c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39H482v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97
            H486.29z M482,359.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75C482.74,357.69,482.07,358.42,482,359.43z M404.73,378.26h-3.01V381
            h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V381h-1.01V378.26z M412.92,379.25c-0.18,1.07-1.21,1.85-2.54,1.85
            c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93
            c0.77,0,1.34-0.39,1.51-0.97H412.92z M408.63,377.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
            C409.36,375.69,408.69,376.42,408.63,377.43z M414.52,377.95c0-1.88,1.25-3.09,3.15-3.12v-1.93h0.98v1.93
            c1.89,0.04,3.15,1.24,3.15,3.12s-1.26,3.09-3.15,3.12v1.96h-0.98v-1.96C415.77,381.04,414.52,379.83,414.52,377.95z M417.67,375.7
            c-1.3,0.03-2.13,0.88-2.13,2.26c0,1.37,0.83,2.22,2.13,2.26V375.7z M418.65,375.7v4.51c1.29-0.03,2.12-0.88,2.12-2.26
            C420.77,376.58,419.95,375.73,418.65,375.7z M427.86,375.74h-1.97V381h-1.01v-5.26h-1.97v-0.84h4.95V375.74z M434.43,379.25
            c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
            v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H434.43z M430.14,377.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
            C430.88,375.69,430.21,376.42,430.14,377.43z M437.33,375.74V381h-1.01v-6.09h3.92v0.84H437.33z M441.51,379.28
            c0-1.04,0.8-1.7,2.21-1.78l1.75-0.1v-0.51c0-0.74-0.49-1.19-1.34-1.19c-0.79,0-1.28,0.37-1.41,0.97h-0.98
            c0.07-1.05,0.97-1.86,2.41-1.86c1.43,0,2.33,0.77,2.33,1.99V381h-0.97v-1.05h-0.02c-0.36,0.7-1.14,1.15-1.96,1.15
            C442.32,381.11,441.51,380.37,441.51,379.28z M445.46,378.69v-0.5l-1.63,0.1c-0.82,0.05-1.29,0.42-1.29,0.99
            c0,0.58,0.49,0.96,1.22,0.96C444.72,380.24,445.46,379.56,445.46,378.69z M448.2,379.42h1.03c0.12,0.53,0.62,0.84,1.38,0.84
            c0.88,0,1.38-0.42,1.38-1.01c0-0.69-0.46-1-1.38-1h-0.91v-0.83h0.91c0.77,0,1.17-0.29,1.17-0.89c0-0.51-0.36-0.88-1.15-0.88
            c-0.72,0-1.22,0.31-1.31,0.8h-0.99c0.13-1.01,1.01-1.65,2.3-1.65c1.39,0,2.14,0.67,2.14,1.65c0,0.62-0.4,1.17-1.01,1.31v0.05
            c0.8,0.18,1.22,0.69,1.22,1.39c0,1.14-0.95,1.9-2.38,1.9C449.28,381.11,448.31,380.45,448.2,379.42z M454.56,377.95
            c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C455.7,381.11,454.56,379.89,454.56,377.95z
             M459.17,377.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S459.17,379.39,459.17,377.95z M467.03,381
            h-1.31l-2.63-2.93h-0.05V381h-1.01v-6.09h1.01v2.7h0.05l2.6-2.7h1.26l-2.85,2.88L467.03,381z M468.16,377.95
            c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C469.3,381.11,468.16,379.89,468.16,377.95z
             M472.77,377.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S472.77,379.39,472.77,377.95z
             M479.64,378.26h-3.01V381h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V381h-1.01V378.26z M482.18,382.61v-2.45h0.51
            c0.45-0.29,0.66-1.05,0.72-1.88l0.23-3.38h4.2v5.26h0.97v2.45h-0.95V381h-4.73v1.61H482.18z M486.83,375.74h-2.29l-0.19,2.57
            c-0.05,0.76-0.21,1.42-0.5,1.8v0.05h2.97V375.74z M495.46,379.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13
            c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97
            H495.46z M491.17,377.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75C491.9,375.69,491.24,376.42,491.17,377.43z M501.37,378.26h-3.01
            V381h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V381h-1.01V378.26z M508.54,376.78c-0.18-0.59-0.67-1.08-1.54-1.08
            c-1.06,0-1.76,0.9-1.76,2.26c0,1.39,0.71,2.26,1.77,2.26c0.81,0,1.34-0.4,1.54-1.07h1.01c-0.2,1.15-1.18,1.97-2.55,1.97
            c-1.69,0-2.8-1.21-2.8-3.15c0-1.9,1.1-3.15,2.79-3.15c1.47,0,2.37,0.94,2.55,1.98H508.54z M511.07,379.28
            c0-1.04,0.8-1.7,2.21-1.78l1.75-0.1v-0.51c0-0.74-0.49-1.19-1.34-1.19c-0.79,0-1.28,0.37-1.41,0.97h-0.98
            c0.07-1.05,0.97-1.86,2.41-1.86c1.43,0,2.33,0.77,2.33,1.99V381h-0.97v-1.05h-0.02c-0.36,0.7-1.14,1.15-1.96,1.15
            C511.89,381.11,511.07,380.37,511.07,379.28z M515.03,378.69v-0.5l-1.63,0.1c-0.82,0.05-1.29,0.42-1.29,0.99
            c0,0.58,0.49,0.96,1.22,0.96C514.29,380.24,515.03,379.56,515.03,378.69z M522.48,375.74h-1.97V381h-1.01v-5.26h-1.97v-0.84h4.95
            V375.74z M528.13,378.26h-3.01V381h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V381h-1.01V378.26z M530.98,377.95
            c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C532.12,381.11,530.98,379.89,530.98,377.95z
             M535.59,377.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S535.59,379.39,535.59,377.95z M543.5,379.25
            c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
            v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H543.5z M539.21,377.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
            C539.95,375.69,539.28,376.42,539.21,377.43z M400.71,399v-6.09h1.24l2.17,4.75h0.05l2.18-4.75h1.19V399h-1.01v-4.32h-0.04
            l-1.97,4.15h-0.78l-1.97-4.15h-0.04V399H400.71z M414.71,397.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13
            c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97
            H414.71z M410.42,395.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75C411.15,393.69,410.49,394.42,410.42,395.43z M420.65,394.78
            c-0.18-0.59-0.67-1.08-1.54-1.08c-1.06,0-1.76,0.9-1.76,2.26c0,1.39,0.71,2.26,1.77,2.26c0.81,0,1.34-0.4,1.54-1.07h1.01
            c-0.2,1.15-1.18,1.97-2.55,1.97c-1.69,0-2.8-1.21-2.8-3.15c0-1.9,1.1-3.15,2.79-3.15c1.47,0,2.37,0.94,2.55,1.98H420.65z
             M427.76,393.74h-1.97V399h-1.01v-5.26h-1.97v-0.84h4.95V393.74z M428.99,395.95c0-1.95,1.14-3.15,2.82-3.15
            c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C430.12,399.11,428.99,397.89,428.99,395.95z M433.59,395.95
            c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S433.59,397.39,433.59,395.95z M442.03,395.95
            c0,1.91-1.05,3.15-2.58,3.15c-0.87,0-1.58-0.43-1.94-1.1h-0.02v3.02h-1.02v-8.13h0.97v1.03h0.02c0.38-0.69,1.08-1.14,1.97-1.14
            C440.97,392.8,442.03,394.04,442.03,395.95z M440.99,395.95c0-1.38-0.68-2.26-1.76-2.26c-1.04,0-1.75,0.9-1.75,2.26
            c0,1.36,0.7,2.26,1.75,2.26C440.31,398.21,440.99,397.34,440.99,395.95z M443.56,395.95c0-1.95,1.14-3.15,2.82-3.15
            c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C444.7,399.11,443.56,397.89,443.56,395.95z M448.17,395.95
            c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S448.17,397.39,448.17,395.95z M458.77,399h-1.31l-2.46-2.93
            h-0.05V399h-1.01v-2.93h-0.04l-2.46,2.93h-1.29l2.74-3.19l-2.67-2.9h1.25l2.43,2.7h0.04v-2.7h1.01v2.7H455l2.43-2.7h1.24
            l-2.66,2.88L458.77,399z M459.71,400.61v-2.45h0.51c0.45-0.29,0.66-1.05,0.72-1.88l0.23-3.38h4.2v5.26h0.97v2.45h-0.95V399h-4.73
            v1.61H459.71z M464.36,393.74h-2.29l-0.19,2.57c-0.05,0.76-0.21,1.42-0.5,1.8v0.05h2.97V393.74z M473,397.25
            c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
            v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H473z M468.71,395.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
            C469.44,393.69,468.77,394.42,468.71,395.43z M478.91,396.26h-3.01V399h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V399h-1.01V396.26z
             M482.05,399v-6.09h1.01v4.54h0.05l3.05-4.54h1.01V399h-1.01v-4.54h-0.05l-3.05,4.54H482.05z M494.34,397.25
            c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
            v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H494.34z M490.05,395.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
            C490.78,393.69,490.11,394.42,490.05,395.43z"/>
                                            <path className="st6" d="M375.35,389.53h-4.93v-6.93h-2.92V372.5h17.38l2.73,3.57v3.33h-2.48v4.91h-3.93l-1.01-1.01V387h-4.84V389.53z
             M371.42,388.53h2.93V386h4.84v-5.12l2.42,2.42h2.52v-4.91h2.48v-1.99l-2.23-2.91H368.5v8.09h2.92V388.53z"/>
                                        </g>
                                        <polygon className="st6" points="498.5,403.5 392.75,403.5 389.75,393.25 364.5,393.25 364.5,369 393.5,369 400.25,350.5 490.5,350.5
        490.5,370 545,370 545,386.25 498.75,386.25 	"/>
                                    </g>
                                    <g id="urengoyskoye" className="active hoverable hidden">
                                        <path className="st7" d="M359.24,457.98v-0.95c0.12,0.03,0.29,0.05,0.47,0.05c0.63,0,1.05-0.26,1.38-0.88l-2.89-6.65h1.13l2.27,5.48
        h0.09l2.21-5.48h1.1l-2.59,6.04c-0.8,1.88-1.46,2.48-2.7,2.48C359.52,458.06,359.34,458.03,359.24,457.98z M371.77,454.95
        c0,1.91-1.05,3.15-2.58,3.15c-0.87,0-1.58-0.43-1.94-1.1h-0.02v3.02h-1.02v-8.13h0.97v1.03h0.02c0.38-0.69,1.08-1.14,1.97-1.14
        C370.72,451.8,371.77,453.04,371.77,454.95z M370.73,454.95c0-1.38-0.68-2.26-1.76-2.26c-1.04,0-1.75,0.9-1.75,2.26
        c0,1.36,0.7,2.26,1.75,2.26C370.05,457.21,370.73,456.34,370.73,454.95z M378.65,456.25c-0.18,1.07-1.21,1.85-2.54,1.85
        c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93
        c0.77,0,1.34-0.39,1.51-0.97H378.65z M374.36,454.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
        C375.09,452.69,374.42,453.42,374.36,454.43z M384.57,455.26h-3.01V458h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V458h-1.01V455.26z
         M388.71,452.74V458h-1.01v-6.09h3.92v0.84H388.71z M392.9,454.95c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15
        c0,1.94-1.14,3.15-2.82,3.15C394.03,458.11,392.9,456.89,392.9,454.95z M397.5,454.95c0-1.44-0.7-2.26-1.78-2.26
        s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S397.5,456.39,397.5,454.95z M400.37,458v-6.09h1.01v4.54h0.05l3.05-4.54h1.01V458h-1.01
        v-4.54h-0.05l-3.05,4.54H400.37z M401.89,449.27c0,0.68,0.44,1.21,1.14,1.21s1.14-0.53,1.14-1.21h0.75c0,1.08-0.77,1.82-1.89,1.82
        s-1.89-0.74-1.89-1.82H401.89z M411.64,453.78c-0.18-0.59-0.67-1.08-1.54-1.08c-1.06,0-1.76,0.9-1.76,2.26
        c0,1.39,0.71,2.26,1.77,2.26c0.81,0,1.34-0.4,1.54-1.07h1.01c-0.2,1.15-1.18,1.97-2.55,1.97c-1.69,0-2.8-1.21-2.8-3.15
        c0-1.9,1.1-3.15,2.79-3.15c1.47,0,2.37,0.94,2.55,1.98H411.64z M419.41,458h-1.31l-2.63-2.93h-0.05V458h-1.01v-6.09h1.01v2.7h0.05
        l2.6-2.7h1.26l-2.85,2.88L419.41,458z M420.54,454.95c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15
        c0,1.94-1.14,3.15-2.82,3.15C421.67,458.11,420.54,456.89,420.54,454.95z M425.14,454.95c0-1.44-0.7-2.26-1.78-2.26
        s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S425.14,456.39,425.14,454.95z M433.06,456.25c-0.18,1.07-1.21,1.85-2.54,1.85
        c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93
        c0.77,0,1.34-0.39,1.51-0.97H433.06z M428.77,454.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
        C429.5,452.69,428.83,453.42,428.77,454.43z M362.73,473.26h-3.01V476h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V476h-1.01V473.26z
         M370.92,474.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18
        c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H370.92z M366.63,472.43h3.31
        c-0.02-1.02-0.66-1.75-1.62-1.75C367.36,470.69,366.69,471.42,366.63,472.43z M372.52,472.95c0-1.88,1.25-3.09,3.15-3.12v-1.93
        h0.98v1.93c1.89,0.04,3.15,1.24,3.15,3.12s-1.26,3.09-3.15,3.12v1.96h-0.98v-1.96C373.77,476.04,372.52,474.83,372.52,472.95z
         M375.67,470.7c-1.3,0.03-2.13,0.88-2.13,2.26c0,1.37,0.83,2.22,2.13,2.26V470.7z M376.65,470.7v4.51
        c1.29-0.03,2.12-0.88,2.12-2.26C378.77,471.58,377.95,470.73,376.65,470.7z M385.86,470.74h-1.97V476h-1.01v-5.26h-1.97v-0.84h4.95
        V470.74z M392.43,474.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18
        c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H392.43z M388.14,472.43h3.31
        c-0.02-1.02-0.66-1.75-1.62-1.75C388.88,470.69,388.21,471.42,388.14,472.43z M395.33,470.74V476h-1.01v-6.09h3.92v0.84H395.33z
         M399.51,474.28c0-1.04,0.8-1.7,2.21-1.78l1.75-0.1v-0.51c0-0.74-0.49-1.19-1.34-1.19c-0.79,0-1.28,0.37-1.41,0.97h-0.98
        c0.07-1.05,0.97-1.86,2.41-1.86c1.43,0,2.33,0.77,2.33,1.99V476h-0.97v-1.05h-0.02c-0.36,0.7-1.14,1.15-1.96,1.15
        C400.32,476.11,399.51,475.37,399.51,474.28z M403.46,473.69v-0.5l-1.63,0.1c-0.82,0.05-1.29,0.42-1.29,0.99
        c0,0.58,0.49,0.96,1.22,0.96C402.72,475.24,403.46,474.56,403.46,473.69z M406.2,474.42h1.03c0.12,0.53,0.62,0.84,1.38,0.84
        c0.88,0,1.38-0.42,1.38-1.01c0-0.69-0.46-1-1.38-1h-0.91v-0.83h0.91c0.77,0,1.17-0.29,1.17-0.89c0-0.51-0.36-0.88-1.15-0.88
        c-0.72,0-1.22,0.31-1.31,0.8h-0.99c0.13-1.01,1.01-1.65,2.3-1.65c1.39,0,2.14,0.67,2.14,1.65c0,0.62-0.4,1.17-1.01,1.31v0.05
        c0.8,0.18,1.22,0.69,1.22,1.39c0,1.14-0.95,1.9-2.38,1.9C407.28,476.11,406.31,475.45,406.2,474.42z M412.56,472.95
        c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C413.7,476.11,412.56,474.89,412.56,472.95z
         M417.17,472.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S417.17,474.39,417.17,472.95z M425.03,476
        h-1.31l-2.63-2.93h-0.05V476h-1.01v-6.09h1.01v2.7h0.05l2.6-2.7h1.26l-2.85,2.88L425.03,476z M426.16,472.95
        c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C427.3,476.11,426.16,474.89,426.16,472.95z
         M430.77,472.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S430.77,474.39,430.77,472.95z M437.64,473.26
        h-3.01V476h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V476h-1.01V473.26z M440.18,477.61v-2.45h0.51c0.45-0.29,0.66-1.05,0.72-1.88
        l0.23-3.38h4.2v5.26h0.97v2.45h-0.95V476h-4.73v1.61H440.18z M444.83,470.74h-2.29l-0.19,2.57c-0.05,0.76-0.21,1.42-0.5,1.8v0.05
        h2.97V470.74z M453.46,474.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18
        c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H453.46z M449.17,472.43h3.31
        c-0.02-1.02-0.66-1.75-1.62-1.75C449.9,470.69,449.24,471.42,449.17,472.43z M459.37,473.26h-3.01V476h-1.01v-6.09h1.01v2.51h3.01
        v-2.51h1.01V476h-1.01V473.26z M466.54,471.78c-0.18-0.59-0.67-1.08-1.54-1.08c-1.06,0-1.76,0.9-1.76,2.26
        c0,1.39,0.71,2.26,1.77,2.26c0.81,0,1.34-0.4,1.54-1.07h1.01c-0.2,1.15-1.18,1.97-2.55,1.97c-1.69,0-2.8-1.21-2.8-3.15
        c0-1.9,1.1-3.15,2.79-3.15c1.47,0,2.37,0.94,2.55,1.98H466.54z M469.07,474.28c0-1.04,0.8-1.7,2.21-1.78l1.75-0.1v-0.51
        c0-0.74-0.49-1.19-1.34-1.19c-0.79,0-1.28,0.37-1.41,0.97h-0.98c0.07-1.05,0.97-1.86,2.41-1.86c1.43,0,2.33,0.77,2.33,1.99V476
        h-0.97v-1.05h-0.02c-0.36,0.7-1.14,1.15-1.96,1.15C469.89,476.11,469.07,475.37,469.07,474.28z M473.03,473.69v-0.5l-1.63,0.1
        c-0.82,0.05-1.29,0.42-1.29,0.99c0,0.58,0.49,0.96,1.22,0.96C472.29,475.24,473.03,474.56,473.03,473.69z M480.48,470.74h-1.97V476
        h-1.01v-5.26h-1.97v-0.84h4.95V470.74z M486.13,473.26h-3.01V476h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V476h-1.01V473.26z
         M488.98,472.95c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15
        C490.12,476.11,488.98,474.89,488.98,472.95z M493.59,472.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26
        S493.59,474.39,493.59,472.95z M501.5,474.25c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13
        c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97
        H501.5z M497.21,472.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75C497.95,470.69,497.28,471.42,497.21,472.43z M358.71,494v-6.09h1.24
        l2.17,4.75h0.05l2.18-4.75h1.19V494h-1.01v-4.32h-0.04l-1.97,4.15h-0.78l-1.97-4.15h-0.04V494H358.71z M372.71,492.25
        c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
        v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H372.71z M368.42,490.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
        C369.15,488.69,368.49,489.42,368.42,490.43z M378.65,489.78c-0.18-0.59-0.67-1.08-1.54-1.08c-1.06,0-1.76,0.9-1.76,2.26
        c0,1.39,0.71,2.26,1.77,2.26c0.81,0,1.34-0.4,1.54-1.07h1.01c-0.2,1.15-1.18,1.97-2.55,1.97c-1.69,0-2.8-1.21-2.8-3.15
        c0-1.9,1.1-3.15,2.79-3.15c1.47,0,2.37,0.94,2.55,1.98H378.65z M385.76,488.74h-1.97V494h-1.01v-5.26h-1.97v-0.84h4.95V488.74z
         M386.99,490.95c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15
        C388.12,494.11,386.99,492.89,386.99,490.95z M391.59,490.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26
        S391.59,492.39,391.59,490.95z M400.03,490.95c0,1.91-1.05,3.15-2.58,3.15c-0.87,0-1.58-0.43-1.94-1.1h-0.02v3.02h-1.02v-8.13h0.97
        v1.03h0.02c0.38-0.69,1.08-1.14,1.97-1.14C398.97,487.8,400.03,489.04,400.03,490.95z M398.99,490.95c0-1.38-0.68-2.26-1.76-2.26
        c-1.04,0-1.75,0.9-1.75,2.26c0,1.36,0.7,2.26,1.75,2.26C398.31,493.21,398.99,492.34,398.99,490.95z M401.56,490.95
        c0-1.95,1.14-3.15,2.82-3.15c1.68,0,2.82,1.21,2.82,3.15c0,1.94-1.14,3.15-2.82,3.15C402.7,494.11,401.56,492.89,401.56,490.95z
         M406.17,490.95c0-1.44-0.7-2.26-1.78-2.26s-1.79,0.82-1.79,2.26s0.71,2.26,1.79,2.26S406.17,492.39,406.17,490.95z M416.77,494
        h-1.31l-2.46-2.93h-0.05V494h-1.01v-2.93h-0.04l-2.46,2.93h-1.29l2.74-3.19l-2.67-2.9h1.25l2.43,2.7h0.04v-2.7h1.01v2.7H413
        l2.43-2.7h1.24l-2.66,2.88L416.77,494z M417.71,495.61v-2.45h0.51c0.45-0.29,0.66-1.05,0.72-1.88l0.23-3.38h4.2v5.26h0.97v2.45
        h-0.95V494h-4.73v1.61H417.71z M422.36,488.74h-2.29l-0.19,2.57c-0.05,0.76-0.21,1.42-0.5,1.8v0.05h2.97V488.74z M431,492.25
        c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
        v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H431z M426.71,490.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
        C427.44,488.69,426.77,489.42,426.71,490.43z M436.91,491.26h-3.01V494h-1.01v-6.09h1.01v2.51h3.01v-2.51h1.01V494h-1.01V491.26z
         M440.05,494v-6.09h1.01v4.54h0.05l3.05-4.54h1.01V494h-1.01v-4.54h-0.05l-3.05,4.54H440.05z M452.34,492.25
        c-0.18,1.07-1.21,1.85-2.54,1.85c-1.75,0-2.81-1.21-2.81-3.13c0-1.89,1.08-3.18,2.76-3.18c1.65,0,2.66,1.2,2.66,3.04v0.39h-4.37
        v0.06c0,1.17,0.69,1.93,1.78,1.93c0.77,0,1.34-0.39,1.51-0.97H452.34z M448.05,490.43h3.31c-0.02-1.02-0.66-1.75-1.62-1.75
        C448.78,488.69,448.11,489.42,448.05,490.43z"/>
                                        <path className="st7" d="M326.69,469.85l-3.7-10.32l7.78-16.12l-1.88-2.5l5.55-13.28l3.07-4.01l-4.3-2.27l-4.41-5.85l9.51-10.92
        l5.17-2.31l4.2-3.16l2.45,3.25l-1.84,5l8.63,0.52l-0.79,2.44l2.46,3.26l-5.84,10.35l-4.13,3.11l-0.58-4.59l0.89-2.07l-2.93,0.04
        l0.21,2.7l-1.54,1.16l1.52,2.02l2.51,1.64l-3.06,2.3l1.92,2.54l-6.27,6.46l4.76,7.29l-2.41,5.4l-1.23,9.38l-6.52,6.44
        L326.69,469.85z M324.07,459.59l3.26,9.09l8.04-1.83l6.08-6l1.23-9.25l2.22-4.97l-4.91-7.5l6.24-6.42l-2-2.66l2.72-2.05l-1.52-1.03
        l-2.18-2.89l1.9-1.43l-0.25-3.21l5.54-0.08l-1.39,3.23l0.33,2.61l2.67-2.01l5.33-9.53l-2.38-3.15l0.55-1.71l-8.68-0.52l2.11-5.75
        l-1.52-2.02l-3.54,2.65l-5.12,2.29l-8.74,10.1l3.86,5.12l5.09,2.62l-3.75,4.9l-5.24,12.58l1.9,2.52L324.07,459.59z"/>
                                        <polygon className="st6" points="458.25,499.5 352.75,499.5 348,473.5 320.75,473.5 316,459.75 328.75,425.75 321.25,415.25
        335.5,399.25 347.75,391.75 365,411.75 351.25,437.75 354.5,444.5 438.25,444.5 438.25,462.25 505.75,462.25 505.75,482.75
        458,482.75 	"/>
                                    </g>
                                </svg>
                            </div>

                            <div className="geoContainer active" id="urengoyskoyeTab">

                                <div className="col-row">
                                    <div className="col-6-12 col-md-4-12 col-sm-5-12 col-xs-12-12">
                                        <div className="geographyDescription" dangerouslySetInnerHTML={{__html: page.DESCRIPTION}} ref={div => this.geographyText = div} />
                                    </div>
                                    <div className="col-4-12 col-md-4-12 col-sm-3-12 hidden-xs"></div>
                                    <div className="col-2-12 col-md-4-12 col-sm-4-12 col-xs-12-12" dangerouslySetInnerHTML={{__html:page.ADD_PREVIEW_TEXT}} />
                                </div>

                            </div>

                            <div className="geoContainer" id="olikuminskoyeTab">

                                <div className="col-row">
                                    <div className="col-6-12 col-md-4-12 col-sm-5-12 col-xs-12-12">
                                        <div className="geographyDescription">Два магистральных газопровода (диаметром
                                            820 мм и 1 420 мм) проходят через Оликуминское месторождение, и два
                                            конденсатных трубопровода (диаметром 377 мм и 529 мм) расположены в 15 км на
                                            запад от лицензионного участка. Эти трубопроводы соединяют Ямбургское
                                            месторождение с Новым Уренгоем и потенциально являются удобным способом
                                            транспортировки углеводородов УГР.
                                        </div>
                                    </div>
                                    <div className="col-4-12 col-md-4-12 col-sm-3-12 hidden-xs"></div>
                                    <div className="col-2-12 col-md-4-12 col-sm-4-12 col-xs-12-12">
                                        <ul className="geographyNumbs">
                                            <li><span>150</span> километров к северу<br/> от г. Новый Уренгой</li>
                                            <li>первые <span>200</span> километров маршрута<br/> Новый Уренгой –
                                                Оликуминское<br/> являются двухполосной<br/> дорогой с твердым покрытием
                                            </li>
                                            <li><span>820</span> мм и <span>1420</span> мм<br/> Два магистральных
                                                газопровода<br/> с такими диаметрами проходят<br/> через Оликуминское
                                                месторождение
                                            </li>
                                            <li>более <span>70</span> млн. т. запасов жидких<br/> углеводородов
                                                АВС1+С2<br/> на государственном балансе
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <Cursor/>
            </div>
            </DocumentMeta>
        );

    }

}